///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

const slugify = require('./slugify');

export function getProductURL(product) {
  const slug = slugify(product.name, '-');
  return `/catalogue/products/${product.id}-${slug}`;
}

export function getProviderURL(provider) {
  const slug = slugify(provider.name, '-');
  return `/catalogue/providers/${provider.id}-${slug}`;
}
