///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { useQuery, useMutation, gql } from '@apollo/client';
import analyticsContext from 'src/shared/contexts/analytics';
import modalContext from 'src/shared/contexts/modal';

const GET_ME = gql`
  query {
    me {
      status
      username
      status
      firstname
      lastname
      can_download
      phone
    }
  }
`;

const RESEND_TOKEN = gql`
  mutation {
    resendToken
  }
`;

const UserContext =  React.createContext();

export default UserContext;

export function UserProvider({ children }) {
  const { data: { me: user } = {}, loading } = useQuery(GET_ME);
  const [doResendToken] = useMutation(RESEND_TOKEN);
  const { showSuccess } = React.useContext(modalContext);
  const { trackUser } = React.useContext(analyticsContext);

  React.useEffect(() => {
    if(user) {
      trackUser(user);
    }
  }, [user]);
  
  const resendToken = async () => {
    await doResendToken();
    showSuccess('Verification Email Resent', (
      <Verification>
        <p>We've sent you an email containing a verification link. Please click this link to verify your email address.</p>
        <p>If you don't see it in your inbox, be sure to check your junk email folder.</p>
      </Verification>
    ));
  }

  const context = { user, loading, resendToken };

  return (
    <UserContext.Provider value={context}>
      {children}
    </UserContext.Provider>
  );
};

const Verification = styled.div`
  max-width: 450px;
  p {
    margin: 10px 0;
  }
`;