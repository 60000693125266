///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import * as theme from 'src/shared/theme';

export default function Footer() {
  return (
    <Container>
      <Inner>
        <Copyright>Copyright © MDX Technology Ltd</Copyright>
        <Links>
          <Link to="/terms">Terms of Use</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/cookie-policy">Cookie Policy</Link>
        </Links>
      </Inner>
    </Container>
  )
}

const Container = styled.footer`
  display: flex;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
`;

const Inner = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${theme.above_mobile} {
    flex-direction: row;
  }
`;

const Copyright = styled.p`
  flex-grow: 1;
  margin: 0;
  ${theme.above_mobile} {
    margin-right: 20px;
  }
`;

const Links = styled.nav`
  display: flex;
  flex-direction: row;
`;

const Link = styled(NavLink)`
  color: inherit;
  text-decoration: underline;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;