///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import Placeholder from 'src/app/components/Placeholder';

export default function Request({ request }) {
  return (
    <>
      <Title>{request.product.name}</Title>
      <SubTitle>Request For {request.product.parent ? 'Sample Access' : 'Quote'}</SubTitle>
      <Placeholder message="Your request has been received, we will get in touch with you shortly." />
    </>
  );
}

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 20px;
`;

const SubTitle = styled.h2`
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 10px;
`;
