///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { Table } from './Table';

export default function DataDictionary({ data_dictionary }) {
  if(!(Array.isArray(data_dictionary) && data_dictionary.length > 1)) {
    return null;
  }
  
  return (
    <Table cellSpacing={0} cellPadding={0}>
      <thead>
        <tr>
          {data_dictionary[0].map((value, i) => (
            <th key={i}>{value}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data_dictionary.slice(1).map((row, i) => (
          <tr key={i}>
            {row.map((value, i) => (
              <td key={i}>{value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}