///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { NavLink } from 'react-router-dom';
import isoContext from 'src/app/contexts/iso';
import userContext from 'src/app/contexts/user';
import basketContext from 'src/app/contexts/basket';
import Placeholder from 'src/app/components/Placeholder';
import { PrimaryButton, WhiteButton } from 'src/app/components/Button';
import QuoteForm from './QuoteForm';

export default function Checkout() {
  const { loginUrl } = React.useContext(isoContext);
  const { empty: emptyBasket, products } = React.useContext(basketContext);
  const { user, resendToken } = React.useContext(userContext);
  const [done, setDone] = React.useState(false);
  
  const onSuccess = () => {
    emptyBasket();
    setDone(true);
  };

  if(done) {
    return (
      <Placeholder message="Your request has been submitted successfully, we will be in touch shortly.">
        <PrimaryButton as="a" href={`/catalogue`}>Return to Catalogue</PrimaryButton>
      </Placeholder>
    );
  }

  if(!products) {
    return null;
  }

  if(products.length === 0) {
    return (
      <Placeholder message="Your basket is currently empty.">
        <PrimaryButton as={NavLink} to="/catalogue">Return to Catalogue</PrimaryButton>
      </Placeholder>
    );
  }

  if(!user) {
    return (
      <Placeholder message="Please sign in to request a quote.">
        <PrimaryButton as="a" href={loginUrl}>Login</PrimaryButton>
        <WhiteButton as="a" href="/register">Sign Up</WhiteButton>
      </Placeholder>
    );
  }

  if(user.status === 'pending') {
    return (
      <Placeholder message="You must activate your account in order to request a quote.">
        <PrimaryButton onClick={resendToken}>Resend Verification Email</PrimaryButton>
      </Placeholder>
    );
  }

  return <QuoteForm onSuccess={onSuccess} user={user} />;
}
