///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';

export default function useClickOutsideHandler(target, handler, watch = []) {
  React.useEffect(() => {
    const innerHandler = (e) => {
      if(target && e.target && !target.contains(e.target)) {
        handler(e);
      }
    };

    document.body.addEventListener('click', innerHandler);
    return () => document.body.removeEventListener('click', innerHandler);
  }, [target, ...watch]);
}