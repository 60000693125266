///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';

export default function Placeholder({ message, children }) {
  return (
    <Frame>
      <FrameMessage>{message}</FrameMessage>
      {children && (
        <Actions>
          {children}
        </Actions>
      )}
    </Frame>
  )
}

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background: #f3f3f3;
  padding: 20px;
`;

const FrameMessage = styled.p`
  max-width: 75%;
  margin: 0;
  font-size: 16px;
  text-align: center;
`;

const Actions = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin-top: 20px;
`;
