///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';

export const Document = styled.div`
  max-width: 60em;
  margin: 0 auto;
  padding: 40px 20px;
  line-height: 1.5;
  * { 
  &:first-child {
    margin-top: 0 !important;
  }
  &:last-child {
    margin-bottom: 0 !important;
  }
  }
  h1 {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 -0.25em;
  }
  h2 {
    font-size: 24px;
    font-weight: normal;
    margin: 0 0 1em;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 1em 0;
  }
  h4 {
    font-weight: bold;
    margin: 1em 0;
  }
  p {
    margin: 1em 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  ol, ul {
    margin: 1em 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    td, th {
      text-align: left;
      border: 1px solid #999;
      padding: 8px 10px;
    }
  }

  dt {
    font-weight: bold;
  }
`;

export function Pt({ as, i, children }) {
  return (
    <Line as={as} collapse={i.match(/^[a-z]\)/)}>
      <Point>{i}</Point>
      <Text>{children}</Text>
    </Line>
  );
}

export function Def({ i, children }) {
  return (
    <Line>
      <Point definition>{i}</Point>
      <Text>{children}</Text>
    </Line>
  );
}

const Line = styled.p`
  display: flex;
  flex-direction: row;
  margin: ${({ collapse }) => collapse ? '0.5em 0 !important' : '1em 0'};
`;

const Point = styled.span`
  width: 40px;
  flex-shrink: 0;
  ${({ definition }) => definition && `
    font-weight: bold;
    width: 150px;
  `}
`;

const Text = styled.span``;