///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from 'src/app/components/Button';
import Placeholder from 'src/app/components/Placeholder';
import DataDictionary from 'src/shared/components/DataDictionary';
import { Section, ProductDownload, ProductPage } from 'src/app/modules/Subscription';
import ConnectionParameters from './ConnectionParameters';

export default function Subscription({ subscription }) {
  const history = useHistory();
  const { product } = subscription;

  const viewProduct = () => {
    const tab = product.parent ? 'samples' : 'quote';
    const productId = product.parent ? product.parent.id : product.id;
    history.push(`/catalogue/products/${productId}?view=${tab}`);
  };

  const dataDictionary = product.data_dictionary || (product.parent && product.parent.data_dictionary) || null;

  return (
    <>
      <Title>{subscription.product.name}</Title>
        {subscription.is_expired ? (
          <Placeholder message="This subscription has expired.">
            <PrimaryButton onClick={viewProduct}>Request a Renewal</PrimaryButton>
          </Placeholder>
        ) : (
          <>
            <Section title="Connection Parameters">
              <ConnectionParameters subscription={subscription} />
            </Section>
            {product.downloads.length > 0 && (
              <Section title="Downloads">
                {product.downloads.map(download => (
                  <ProductDownload key={download.id} download={download} />
                ))}
              </Section>
            )}
            {dataDictionary && dataDictionary.length > 0 && (
              <Section title="Data Dictionary">
                <DataDictionary data_dictionary={dataDictionary} />
              </Section>
            )}
            {subscription.product.pages.length > 0 && (
              <Section title="Data Tables">
                {subscription.product.pages.map(page => (
                  <ProductPage key={page.id} page={page} />
                ))}
              </Section>
            )}
          </>
      )}
    </>
  );
}

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 20px;
`;
