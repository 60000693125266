///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';

export default function Section({ title, children }) {
  const [isOpen, setOpen] = React.useState(true);

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <Container>
      <Header onClick={toggleOpen}>
        <Title>{title}</Title>
        <Icon><i className={`fa fa-chevron-${isOpen ? 'down' : 'left'}`} /></Icon>
      </Header>

      {isOpen && (
        <Body>{children}</Body>
      )}
    </Container>
  );
}

const Container = styled.section`
  margin-bottom: 20px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  background: #f3f3f3;
  padding: 8px 10px;
  cursor: pointer;
`;

const Title = styled.h2`
  font-size: 18px;
  line-height: 26px;
  height: 26px;
  margin: 0;
  flex-grow: 1;
  font-weight: normal;
`;

const Icon = styled.div`
  line-height: 26px;
  height: 26px;
  text-align: center;
  opacity: 0.5;
`;

const Body = styled.div`
  margin-top: 10px;
  border-top: none;
`;