///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';

export default function Flag({ children, className }) {
 return (
    <Container className={className}>
      <FlagIcon className="fa fa-exclamation-triangle" />
      <FlagText>{children}</FlagText>
    </Container>
  ); 
}

const Container = styled.div`
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${theme.warning};
  font-weight: normal;
  font-size: 12px;
`;

const FlagIcon = styled.i`
  display: inline-flex;
  font-size: 12px;
  margin-right: 6px;
`;

const FlagText = styled.div`
  font-size: 12px;
  padding: 2px 0;
`;