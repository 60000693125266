///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { WhiteButton } from 'src/app/components/Button';

export default function SampleButton({ disabled, sampleRequested, requestSample }) {
  if(disabled) {
    return null;
  }
  
  if(sampleRequested) {
    return <WhiteButton shadow disabled>Sample Access Requested</WhiteButton>;
  }

  return <WhiteButton shadow onClick={requestSample}>Request Sample Access</WhiteButton>;
}
