///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { PrimaryButton } from 'src/app/components/Button';

export default function QuoteButton({ disabled, quoteRequested, inBasket, requestQuote }) {
  if(disabled) {
    return null;
  }
  
  if(quoteRequested) {
    return <PrimaryButton shadow disabled>Quote Requested</PrimaryButton>;
  }
  
  if(inBasket) {
    return <PrimaryButton shadow disabled>Added To Basket</PrimaryButton>;
  }
  
  return <PrimaryButton shadow onClick={requestQuote}>Request a Quote</PrimaryButton>;
}
