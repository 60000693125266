///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import RichText from 'src/shared/components/RichText';
import DataDictionary from 'src/shared/components/DataDictionary';
import { Section } from 'src/app/modules/Subscription';

export default function ProductInformation({ product }) {
  if(!(product.description || product.data_dictionary)) {
    return null;
  }

  const { data_dictionary } = product;

  return (
    <Description>
      {product.description && (
        <Section title="Product Description">
          <RichText>{product.description}</RichText>
        </Section>
      )}
      {data_dictionary && data_dictionary.length > 0 && (
        <Section title="Data Dictionary">
          <DataDictionary data_dictionary={data_dictionary} />
        </Section>
      )}
    </Description>
  );
}

const Description = styled.div`
  padding: 10px;
  flex-shrink: 0;
  line-height: 1.45;
`;
