///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';

export default function useQueryParams() {
  const location = useLocation();
  const history = useHistory();
  const query = React.useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);

  const setQuery = (query) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(query, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets' }),
    });
  };

  return [query, setQuery];
}