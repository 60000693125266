///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import * as theme from 'src/shared/theme';

export default function NavItem({ icon, children, ...attrs }) {
  return (
    <Container activeClassName="is-active" {...attrs}>
      <Icon><i className={icon} /></Icon>
      <Text>{children}</Text>
    </Container>
  );
};

const Text = styled.span`
  line-height: 25px;
  height: 25px;
`;

const Container = styled(NavLink)`
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 22px;
  height: 42px;
  text-decoration: none;
  cursor: pointer;
  border-radius: none;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  background: transparent;
  border: none;
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  &.is-active {
    background: url(/bg4.png) #f3f3f3;
    border-bottom: none;
    color: black;
  }
  ${theme.above_mobile} {
    border-bottom: none;
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  background: white;
  color: black;
  border-radius: 100%;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  display: none;
  margin-right: 10px;
`;
