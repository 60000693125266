///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import analyticsContext from 'src/shared/contexts/analytics';
import * as theme from 'src/shared/theme';
import { Footer } from 'src/app/components/Layout';
import { Header } from 'src/app/components/Sidebar';
import RichText from 'src/shared/components/RichText';
import BackButton from 'src/app/components/BackButton';
import Products from 'src/app/components/Products';
import { CustomBlankSlate } from 'src/app/components/BlankSlate';

const GET_PROVIDER = gql`
  query($id: ID!) {
    provider(id: $id) {
      id
      name
      description
      logo_url

      products {
        id
        name
        excerpt
        is_hidden
        has_sample

        provider {
          id
          name
          logo_url
          is_hidden
        }
      }
    }
  }
`;

export default function Provider() {
  let { id } = useParams();
  id = id && id.split('-')[0];

  const { trackEvent } = React.useContext(analyticsContext);
  const { data: { provider } = {} } = useQuery(GET_PROVIDER, { variables: { id } });
  const backRoute = '/catalogue/providers';

  React.useEffect(() => {
    if(provider) {
      const event = { event_category: 'engagement', name: provider.name, id: provider.id };
      trackEvent('view_provider', event);
    }
  }, [provider]);

  if(!provider) {
    return null;
  }

  return (
    <Wrapper>
      <Contents>
        <ContentsHeader>
        <BackButton url={backRoute} />
        <ProductsTitle>Products</ProductsTitle>
        </ContentsHeader>
        <Listing>
          {provider.products.length > 0 ? (
            <Products products={provider.products} />
          ) : (
            <CustomBlankSlate title="Coming soon" message={`Products from ${provider.name} will be available on the MDX Marketplace soon.`} />
          )}
        </Listing>
        <Footer />
      </Contents>
      <Sidebar>
        <Header creator={provider} noteAfter={`${provider.products.length.toLocaleString()} products available`} />
        {provider.description && (
          <Description>{provider.description}</Description>
        )}
      </Sidebar>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  ${theme.above_mobile} {
    flex-direction: row;
    overflow: hidden;
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${theme.above_mobile} {
    overflow-y: auto;
  }
`;

const Sidebar = styled.div`
  box-sizing: border-box;
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  background: white;
  display: flex;
  flex-direction: column;
  ${theme.above_mobile} {
    width: 400px;
    flex-shrink: 0;
  }
`;

const ProductsTitle = styled.h2`
  font-size: 26px;
  font-weight: bold;
  margin: 0;
`;

const Description = styled(RichText)`
  text-align: left;
  font-size: 16px;
  margin-top: 20px;
  overflow-y: auto;
`;

const Listing = styled.div`
  flex-grow: 1;
`;

const ContentsHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 10px 10px;
  ${theme.above_mobile} {
    margin: 15px 20px 0;
  }
`;