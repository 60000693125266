///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';

export default function Section({ name, note, onToggle, isOpen = true, children, onClear }) {
  const handleClear = (e) => {
    e.stopPropagation();
    onClear();
  };

  return (
    <Container>
      <Header onClick={onToggle}>
        <Title>{name}</Title>
        {note && (
          <NoteWrapper>
            <Note>{note}</Note>
            <ClearButton onClick={handleClear}><i className="fa fa-times" /></ClearButton>
          </NoteWrapper>
        )}
        {onToggle && (<Toggle className={`fa fa-chevron-${isOpen ? 'down' : 'left'}`} />)}
      </Header>
      {isOpen && (
        <Contents>
          {children}
        </Contents>
      )}
    </Container>
  );
}

const Toggle = styled.i`
  font-size: 14px;
  opacity: 0.25;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  padding: 8px 10px;
  align-items: center;
  cursor: pointer;
  background: #f3f3f3;
  border-top: 1px solid #ddd;
  &:hover {
    background: #eee;
    > ${Toggle} {
      opacity: 0.5;
    }
  }
`;

const Container = styled.section`
  &:last-child > ${Header}:last-child {
    border-bottom: 1px solid #ddd;
  }
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  user-select: none;
  flex-grow: 1;
`;

const NoteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 5px 0 5px;
`;

const Note = styled.div`
  font-size: 14px;
  font-weight: normal;
  opacity: 0.5;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid #ddd;
`;

const ClearButton = styled.button`
  box-sizing: border-box;
  background: transparent;
  border: none;
  margin: 0;
  padding: 5px 10px;
  height: 22px;
  line-height: 12px;
  opacity: 0.5;
  cursor: pointer;
`;