///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import * as React from 'react';
import styled from 'styled-components';

export default function Checkbox({ checked, onChange }) {
  const handleClick = (event) => {
    event.stopPropagation();
    onChange();
  }

  return (
    <Container onClick={handleClick}>
      {checked && <i className="fa fa-check" />}
    </Container>
  );
}

const Container = styled.div`
  background: white;
  border: 1px solid #ddd;
  width: 21px;
  height: 21px;
  text-align: center;
  line-height: 21px;
`;