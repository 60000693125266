///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
const slugify = require('src/shared/lib/slugify');

export default function FacetValue({ facet, value, isSelected, onSelect, children }) {
  const [collapsed, setCollapsed] = React.useState(true);
  const [highlight, setHighlight] = React.useState(false);

  const handleToggle = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setCollapsed(!collapsed);
  };

  return (
    <OptionGroup key={value.id || value.label}>
      <Option>
        <OptionSelect highlight={highlight}>
          {facet.is_multi ? (
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => onSelect(value)}
            />
          ) : (
            <input
              type="radio"
              name={slugify(facet.name)}
              checked={isSelected}
              onChange={() => onSelect(value)}
            />
          )}
          <OptionLabel>{value.label}</OptionLabel>
          {value.count ? <Count> ({value.count})</Count> : null}
        </OptionSelect>
        {children && (
          <Toggle onClick={handleToggle} onMouseEnter={() => setHighlight(true)} onMouseLeave={() => setHighlight(false)}>
            <i className={`fa fa-chevron-${collapsed ? 'down' : 'up'}`} />
          </Toggle>
        )}
      </Option>
      {children && !collapsed && (
        <ChildOptions>
          {children}
        </ChildOptions>
      )}
    </OptionGroup>
  );
}

const OptionGroup = styled.div`

`;

const OptionLabel = styled.div`
  padding: 0 5px;
  user-select: none;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0;
`;

const OptionSelect = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  ${({ highlight }) => highlight && `
    ${OptionLabel} {
      background: #ddd;
    }
  `}
  &:hover ${OptionLabel} {
    background: #ddd;
  }
`;

const Toggle = styled.div`
  opacity: 0.5;
  cursor: pointer;
  padding: 0 10px;
  margin-right: -10px;
`;

const ChildOptions = styled.div`
  padding-left: 20px;
`;

const Count = styled.span`
  opacity: 0.5;
  margin-left: 2px;
`;