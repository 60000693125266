///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import * as theme from 'src/shared/theme';
import { getProductURL, getProviderURL } from 'src/shared/lib/urls';
import { Header } from 'src/app/components/Sidebar';

export default function ProductSidebar({ product }) {
  const { related_products, facet_values } = product;

  return (
    <Sidebar>
      <Provider to={getProviderURL(product.provider)}>
        <Header creator={product.provider} noteBefore="Provided by" />
      </Provider>
      {facet_values.length > 0 && (
        <Section>
          {facet_values.map(({ facet, id, label }) => {
            return (
              <Attribute key={id}>
                <Key>{facet.name}</Key>
                <Value>{label}</Value>
              </Attribute>
            );
          })}
        </Section>
      )}
      {related_products.length > 0 && (
        <Section>
          <SectionTitle>Related Products</SectionTitle>
          {related_products.map(related => (
            <RelatedProduct key={related.id} to={getProductURL(related)}>
              <ProductTitle>{related.name}</ProductTitle>
            </RelatedProduct>
          ))}
        </Section>
      )}
    </Sidebar>
  );
}

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 10;
  flex-shrink: 0;
  background: white;
  overflow-y: auto;
  ${theme.above_mobile} {
    width: 400px;
  }
`;

const Provider = styled(NavLink)`
  display: block;
  color: inherit;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Section = styled.div`
  padding: 5px 20px 20px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  margin: 0 0 10px;
  font-weight: normal;
`;

const RelatedProduct = styled(NavLink)`
  padding: 10px;
  display: block;
  color: inherit;
  text-decoration: none;
  box-shadow: 0 1px 2px rgba(0,0,0,0.25);
  border: 1px solid #ccc;
  cursor: pointer;
  margin-bottom: 10px;
  background: #f3f3f3;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const ProductTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Attribute = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
`;

const Key = styled.div`
  flex-grow: 1;
  font-weight: bold;
`;

const Value = styled.div``;