///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { formatError } from 'graphql';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export function bugsnagEnabled() {
  return ['production', 'staging'].includes(process.env.NODE_ENV);
}

function log(message, level) {
  if(message.toString().includes('session(s) reported')) {
    return;
  }

  const logger = console[level].bind(console);
  logger(message);
}

export function startBugsnag(plugins) {
  Bugsnag.start({
    apiKey: 'e20928e4f377e064c99ad6c057648750',
    plugins,
    logger: bugsnagLogger,
  });

  return Bugsnag;
}

const bugsnagLogger = ['debug', 'info', 'warn', 'error'].reduce((acc, level) => {
  acc[level] = message => log(message, level);
  return acc;
}, {});

export function reportGraphqlError(err) {
  if(err.extensions?.exception?.stacktrace) {
    err.stack = err.extensions.exception.stacktrace.join('\n');
  }

  if(bugsnagEnabled()) {
    Bugsnag.notify(err);
  }
  
  return formatError(err);
}

export function startBugsnagClientSide() {
  if(bugsnagEnabled()) {
    startBugsnag([new BugsnagPluginReact()]);
  }
}

export function BugsnagBoundary({ children }) {
  if(bugsnagEnabled()) {
    const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
    return <ErrorBoundary>{children}</ErrorBoundary>;

  } else {
    return <>{children}</>;
  }
}