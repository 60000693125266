///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;

  td, th {
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 8px 10px;
    box-sizing: border-box;
    height: 36px;
    background: white;
    color: rgba(0, 0, 0, 0.75);
    vertical-align: top;
    text-align: left;
  }
  th {
    font-weight: bold;
    background: #f3f3f3;
  }
  tr td:first-child, tr th:first-child {
    border-left: 1px solid #ddd;
  }
  tbody tr:last-child td {
    border-bottom: 1px solid #ddd;
  }
`;
