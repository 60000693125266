///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import * as theme from 'src/shared/theme';
import { getProductURL } from 'src/shared/lib/urls';
import Flag from 'src/app/components/Flag';
import BlankSlate from 'src/app/components/BlankSlate';
import FakeText from 'src/shared/components/FakeText';
import RichText from 'src/shared/components/RichText';

import ProviderNotice, { FakeProviderNotice } from './ProviderNotice';

const Product = React.memo(({ product }) => {
  const history = useHistory();

  return (
    <ProductWrapper onClick={() => history.push(getProductURL(product))}>
      <ProviderNotice provider={product.provider} />
      <ProductInfo>
        <ProductTitle>{product.name}</ProductTitle>
        {product.excerpt && <ProductExcerpt><RichText>{product.excerpt}</RichText></ProductExcerpt>}
      </ProductInfo>
      {product.has_sample && (
        <SampleTag>
          <SampleIcon><i className="fa fa-asterisk" /></SampleIcon>
          <SampleText>Sample Data Available</SampleText>
        </SampleTag>
      )}
      {(product.is_hidden || product.provider.is_hidden) && (
        <Flag>not publicly visible</Flag>
      )}
      <HoverOverlay />
    </ProductWrapper>
  );
}, (prev, next) => prev.product.id === next.product.id);

Product.name = 'Product';

export default function Products({ products }) {
  const [limit, setLimit] = React.useState(50);
  if(!products) return null;

  const increaseLimit = () => {
    setLimit(limit + 50);
  }

  if(products.length === 0) {
    return <BlankSlate type="products" />;
  }

  return (
    <>
      <ProductsWrapper>
        {products.map((product, i) => i >= limit ? null : (
          <Product key={product.id} product={product} />
        ))}
      </ProductsWrapper>
      <Waypoint onEnter={increaseLimit} bottomOffset="-250px" />
    </>
  );
}

export function FakeProducts({ products }) {
  return (
    <ProductsWrapper>
      {Array(products).fill().map((_, i) => (
        <FakeProduct key={i}>
          <FakeProviderNotice />
          <ProductInfo>
            <ProductTitle><FakeText /></ProductTitle>
            <ProductExcerpt><FakeText opacity={0.25} /></ProductExcerpt>
          </ProductInfo>
        </FakeProduct>
      ))}
    </ProductsWrapper>
  );
}

const ProductsWrapper = styled.div`
  overflow: hidden;
  padding: 10px 0 0 10px;
  flex-shrink: 0;
  ${theme.above_mobile} {
    padding: 20px 10px 10px 20px;
  }
  @supports (display: grid) {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    padding: 10px;
    ${theme.above_mobile} {
      padding: 20px;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1366px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 1500px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
`;

const HoverOverlay = styled.div`
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
`;

const ProductWrapper = styled.article`
  box-sizing: border-box;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 180px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  cursor: pointer;
  &:hover ${HoverOverlay} {
    display: block;
  }
  // below: IE-only to simulate grid. Doesn't equalize height.
  margin: 0 10px 10px;
  ${theme.above_mobile} {
    display: inline-flex;
    margin: 0 10px 10px 0;
    width: calc(50% - 10px);
  }
  @media (min-width: 1024px) {
    width: calc(33.33% - 10px);
  }
  @media (min-width: 1366px) {
    width: calc(25% - 10px);
  }
  @media (min-width: 1500px) {
    width: calc(20% - 10px);
  }
  @supports (display: grid) {
    width: 100% !important;
    margin: 0 !important;
  }
`;

const FakeProduct = styled(ProductWrapper)`
  > * {
    opacity: 0.5;
  }
`;

const ProductTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;

const ProductInfo = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  padding: 10px;
`;

const ProductExcerpt = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 5px 0 0;
  height: 4.2em;
  overflow: hidden;
`;

const SampleTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  background: #f3f3f3;
`;

const SampleIcon = styled.div`
  font-size: 12px;
  margin-right: 6px;
`;

const SampleText = styled.div`
  font-size: 12px;
  padding: 2px 0;
`;
