///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import useQueryParams from './useQueryParams';

export default function useQueryParam(key) {
  const [query, setQuery] = useQueryParams();
  const value = React.useMemo(() => query[key], [query, key]);

  const setValue = (value) => {
    setQuery({ ...query, [key]: value });
  };

  return [value, setValue];
}