///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import chroma from 'chroma-js';

export const brand_primary = '#ffc600';
export const brand_primary_border = chroma(brand_primary).darken(0.5).css();
export const brand_primary_darken = chroma(brand_primary).darken(1).css();

export const text_primary = '#000';
export const zebra_stripe = '#f6f6f6';

export const background = '#f3f3f3';
export const background_border = chroma(background).darken(0.5).css();
export const background_darken = chroma(background).darken(1).css();

export const danger = '#f55';
export const danger_border = chroma(danger).darken(0.5).css();
export const danger_darken = chroma(danger).darken(1).css();
export const danger_background = chroma(danger).alpha(0.125).css();

export const success = '#6d6';
export const success_border = chroma(success).darken(0.5).css();
export const success_darken = chroma(success).darken(1).css();

export const warning = '#ffd655';
export const warning_border = chroma(warning).darken(0.5).css();
export const warning_darken = chroma(warning).darken(1).css();

export const highlight = '#cdf';

export const above_mobile = '@media (min-width: 768px)';
