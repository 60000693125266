///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';

export default function Checkbox({ label, value, description, onChange }) {
  return (
    <Container>
      <Input type="checkbox" checked={value} onChange={() => onChange(!value)} />
      <Label>
        <Name>{label}</Name>
        <Description>{description}</Description>
      </Label>
    </Container>
  );
}

const Name = styled.span`
  flex-grow: 1;
`;

const Container = styled.label`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 3px 0;
  cursor: pointer;
  &:hover ${Name} {
    text-decoration: underline;
  }
`;

const Label = styled.div`
  flex-grow: 1;
  margin-left: 10px;
`;

const Input = styled.input`

`;

const Description = styled.p`
  opacity: 0.5;
  margin: 5px 0 10px;
`;