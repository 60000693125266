///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';
import { PrimaryButton, GreyButton } from 'src/app/components/Button';
import toggleInList from 'src/shared/lib/toggleInList';

export default function Consent({ onConsent }) {
  const cookiePolicyUrl = `https://mdxtechnology.com/cookie-policy`;
  const [showOptions, setShowOptions] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState(['required', 'analytics']);

  const acceptSelected = () => {
    onConsent(selectedOptions);
  };

  const moreOptions = () => {
    setShowOptions(true);
  }

  return (
    <Container data-testid="cookie-consent">
      <Title>We value your privacy</Title>
      <Text>We use cookies to provide important services on this website such as account login,
        as well as utilising third-party services which make use of cookies in order to analyse our
        traffic and improve the performance and usability of the site. You can learn about how we use
        cookies in our <Link href={cookiePolicyUrl} target="_blank">Cookie Policy.</Link>.</Text>
      {showOptions ? (
        <>
          <Table cellSpacing={0} cellPadding={0}>
            <tbody>
              <tr>
                <td>
                  <OptionTitle>Required Cookies</OptionTitle>
                  <Text>These cookies are required for the site to function correctly.</Text>
                </td>
                <CheckCell>
                  <input type="checkbox" checked disabled data-testid="cookie-consent/required" />
                </CheckCell>
              </tr>
              <tr>
                <td>
                  <OptionTitle>Performance Cookies</OptionTitle>
                  <Text>Performance cookies help us to improve the performance of the site.</Text>
                </td>
                <CheckCell>
                  <input type="checkbox" checked={selectedOptions.includes('analytics')} onChange={() => setSelectedOptions(toggleInList('analytics'))} data-testid="cookie-consent/performance" />
                </CheckCell>
              </tr>
            </tbody>
          </Table>
          <Actions>
            <PrimaryButton onClick={acceptSelected} data-testid="cookie-consent/save">Save Preferences</PrimaryButton>
          </Actions>
        </>
      ) : (
        <Actions>
          <PrimaryButton onClick={acceptSelected} data-testid="cookie-consent/accept-all">Accept All</PrimaryButton>
          <GreyButton onClick={moreOptions} data-testid="cookie-consent/more-options">More Options</GreyButton>
        </Actions>
      )}
    </Container>
  );
}

const Container = styled.div`
  max-width: 600px;
  padding: 5px 10px 10px;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px;
`;

const Text = styled.p`
  margin: 0;
`;

const Link = styled.a`
  color: ${theme.brand_primary};
  text-decoration: underline;
`;

const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 10px;
  }
`;

const OptionTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;

const Table = styled.table`
  margin: 20px 0;
  border-collapse: collapse;
  width: 100%;

  td {
    border: 1px solid #ccc;
    padding: 8px 10px;
  }
`;

const CheckCell = styled.td`
  width: 1px;
`;