///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import modalContext from 'src/shared/contexts/modal';
import Layout from './Layout';
import Directory from './Directory';
import Product from './Product';
import Providers from './Providers';
import Provider from './Provider';
import Subscriptions from './Subscriptions';
import Downloads from './Downloads';
import Checkout from './Checkout';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';
import CookiePolicy from './CookiePolicy';

export default function Catalogue() {
  const { showError } = React.useContext(modalContext);

  React.useEffect(() => {
    const ERROR_KEY = 'error';
    const error = Cookies.get(ERROR_KEY);
    if(error) {
      Cookies.remove(ERROR_KEY);
      showError(error);
    }
  }, []);
  
  return (
    <Layout>
      <Switch>
        <Route exact path="/catalogue/products" component={Directory} />
        <Route exact path="/catalogue/products/:id" component={Product} />
        <Route exact path="/catalogue/providers" component={Providers} />
        <Route exact path="/catalogue/providers/:id" component={Provider} />
        <Route exact path="/catalogue/checkout" component={Checkout} />
        <Route exact path="/subscriptions" component={Subscriptions} />
        <Route exact path="/subscriptions/:type" component={Subscriptions} />
        <Route exact path="/downloads" component={Downloads} />
        <Route exact path="/downloads/:id" component={Downloads} />
        <Route exact path="/subscriptions/:type/:recordtype/:id" component={Subscriptions} />
        <Route exact path="/terms" component={TermsOfUse} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/cookie-policy" component={CookiePolicy} />
        <Redirect to="/catalogue/products" />
      </Switch>
    </Layout>
  );
};
