///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import { parseISO, isBefore } from 'date-fns';

export default function hasExpired(sub) {
  return !isBefore(new Date(), parseISO(sub.expires_at));
}