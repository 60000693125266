///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';

export default function ProductDownload({ download }) {
  const type = download.type || download.path.match(/\.([^.]+)$/)[1];
  const url = `https://content.mdxtechnology.com/${download.path}`;
  return (
    <Container>
      <Label>{download.name}</Label>
      <Extension> ({type})</Extension>
      <Button href={`/forward?url=${btoa(url)}`}>
        <Icon><i className="fa fa-fw fa-download" /></Icon>
        <ButtonText>Download</ButtonText>
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${theme.background};
  box-shadow: 0 1px 2px rgba(0,0,0,0.25);
  border: 1px solid #ccc;
  text-decoration: none;
  color: inherit;
  
  & + & {
    margin-top: 10px;
  }
`;

const Label = styled.div`
  padding-left: 10px;
`;

const Extension = styled.div`
  flex-grow: 1;
  opacity: 0.5;
  margin-left: 8px;
`;

const Button = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  
  position: relative;
  box-sizing: border-box;
  border: none;
  font-family: inherit;
  text-decoration: none;
  color: inherit;
  padding: 0;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  height: 36px;
  line-height: 20px;
  border: 1px solid ${theme.brand_primary_border};
  background: ${theme.brand_primary};
  &:hover {
    background: ${theme.brand_primary_border};
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 36px;
  width: 36px;
  background: ${theme.brand_primary_border};
  border-right: 1px solid ${theme.brand_primary_darken};
`;

const ButtonText = styled.div`
  padding: 8px 16px;
`;