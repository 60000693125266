///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { remove, append } from 'ramda';
import * as theme from 'src/shared/theme';
import useCache from 'src/shared/lib/useCache';
import { gql, useQuery } from '@apollo/client';
import NestedFolder from './NestedFolder';

const GET_DOWNLOADS = gql`
  fragment FolderFields on DownloadFolder {
    __typename
    id
    name
    
    files {
      id
      name
      type
    }
  }

  query {
    downloadFolder {
      ...FolderFields
      folders {
        ...FolderFields
        folders {
          ...FolderFields
          folders {
            ...FolderFields
            folders {
              ...FolderFields
              folders {
                ...FolderFields
                folders {
                  ...FolderFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function DownloadsTree({ selected }) {
  const { data: { downloadFolder: tree } = {} } = useQuery(GET_DOWNLOADS);
  const [openFolders, setOpenFolders] = React.useState([null]);
  const treeCache = useCache(tree);
  const selectedCache = useCache(selected);

  const toggleFolder = (id) => {
    const index = openFolders.indexOf(id);
    if(index >= 0) {
      setOpenFolders(remove(index, 1));

    } else {
      setOpenFolders(append(id));
    }
  };

  const selectedIds = [];
  if(selectedCache) {
    for(let cond = selectedCache; cond; cond = cond.parent) {
      selectedIds.push(cond.id);
    }
  }

  return (
    <Tree>
      {treeCache && (
        <NestedFolder
          ancestors={[]}
          folder={treeCache}
          selected={selectedIds}
          openFolders={openFolders}
          onToggle={id => toggleFolder(id)}
        />
      )}
    </Tree>
  );
}

const Tree = styled.div`
  flex-shrink: 0;
  background: url(/bg4.png) #f3f3f3;
  padding: 20px;
  display: none;
  ${theme.above_mobile} {
    display: block;
    width: 400px;
    overflow-y: auto;
  }
`;
