///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';
import { UserProvider } from 'src/app/contexts/user';
import { BasketProvider } from 'src/app/contexts/basket';
import Header from './Header';

export default function Layout({ children }) {
  return (
    <UserProvider>
      <BasketProvider>
        <Page>
          <Header />
          <Contents>
            {children}
          </Contents>
        </Page>
      </BasketProvider>
    </UserProvider>
  );
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  ${theme.above_mobile} {
    overflow-y: hidden;
  }
`;

const Contents = styled.main`
  background: url(/bg4.png) #f3f3f3;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex-shrink: 0;
  ${theme.above_mobile} {
    overflow-y: auto;
    flex-shrink: 1;
  }
`;
