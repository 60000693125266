///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import styled from 'styled-components';
import * as theme from 'src/shared/theme';

export const Message = styled.div`
  margin: 0 0 20px;
  padding: 8px 10px;
  width: calc(100% - 20px);
  ${({ type }) => {
    switch(type) {
      case 'error':
        return `
          background: ${theme.danger};
          color: white;
          text-shadow: 0 1px 1px black;
        `;
      case 'success':
        return `background: ${theme.success};`;
    }
  }}
`;

export const MessageTitle = styled.div``;

export const MessageList = styled.ul`
  margin: 10px 0 0;
  padding-left: 20px;
  padding-bottom: 10px;
`;

export const Table = styled.table`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Row = styled.tr`
  &:first-child > td {
    border-top: 1px solid #ddd;
  }
`;

export const Value = styled.td`
  padding: 8px 10px;
  box-sizing: border-box;
  height: 36px;
  background: white;
  color: rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  vertical-align: top;
  &:first-child {
    border-left: 1px solid #ddd;
  }
`;

export const InputCell = styled.td`
  background: white;
  color: rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  &:first-child {
    border-left: 1px solid #ddd;
  }
`;

export const Key = styled(Value)`
  font-weight: bold;
`;

export const Placeholder = styled.span`
  opacity: 0.5;
`;

export const Header = styled.header`
  margin: 0 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const HeaderActions = styled.div`
  margin-bottom: 10px;
`;

export const Heading = styled.h1`
  font-size: 20px;
  flex-grow: 1;
  margin: 0;
  padding: 0;
`;

export const Subheading = styled.h2`
  font-size: 16px;
  flex-grow: 1;
  margin: 0;
  padding: 0;
`;

export const Input = styled.input`
  box-sizing: border-box;
  height: 36px;
  width: 100%;
  border: none;
  padding: 8px 10px;
  box-shadow: none;
  background: transparent;
  ${({ modifier }) => {
    switch(modifier) {
      case 'outlined':
        return `
          border-radius: 3px;
          border: 1px solid #999;
          box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
          background: white;
        `;
      case 'upload':
        return `
          background: #f3f3f3;
        `;
      default:
        return '';
    }
  }}
`;

export const Textarea = styled.textarea`
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: none;
  padding: 8px 10px;
  box-shadow: none;
  margin: 0 !important;
  ${({ modifier }) => {
    switch(modifier) {
      case 'outlined':
        return `
          border-radius: 3px;
          border: 1px solid #999;
          box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
          background: white;
        `;
      default:
        return '';
    }
  }}
`;

export const Select = styled.select`
  box-sizing: border-box;
  height: 36px;
  width: 100%;
  border: none;
  padding: 8px 10px;
  box-shadow: none;
  ${({ modifier }) => {
    switch(modifier) {
      case 'outlined':
        return `
          border-radius: 3px;
          border: 1px solid #999;
          box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
          background: white;
        `;
      default:
        return '';
    }
  }}
`;

export const Section = styled.section`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const FieldTitle = styled.h3`
  font-size: inherit;
  font-weight: bold;
  margin: 0 0 10px;
  color: rgba(0, 0, 0, 0.75);
`;

export const InlineSelect = styled.select`
  box-sizing: border-box;
  height: 30px;
  display: block;
  font-size: 14px;
  font-weight: 300;
  padding: 0 0.5em;
  border-radius: 3px;
  border: 1px solid #999;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`;

export const InlineInput = styled.input`
  box-sizing: border-box;
  height: 30px;
  display: block;
  font-size: 14px;
  font-weight: 300;
  padding: 0 0.5em;
  border-radius: 3px;
  border: 1px solid #999;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`;

export const Footer = styled.footer`
  margin-top: 20px;
`;