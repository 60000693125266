///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { pickAll } from 'ramda';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import modalContext from './modal';

const context = React.createContext();
export default context;

if(typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];

  window.gtag = function gtag() {
    window.dataLayer.push(arguments);
  }
}

export function AnalyticsProvider({ children }) {
  const location = useLocation();
  const modal = React.useContext(modalContext);
  const consentCookie = Cookies.get('gdpr-consent');

  const gID = typeof window === 'undefined' ? '' : window.ISO_CONTEXT.analyticsId;
  
  const injectScript = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gID}`;
    document.head.appendChild(script);
    gtag('js', new Date());
  };

  const trackView = (path) => {
    gtag('config', gID, { page_path: path });
  };

  const trackEvent = (action, info) => {
    gtag('event', action, info);
  };

  const trackUser = (user) => {
    const userProps = pickAll(['username', 'firstname', 'lastname', 'company'], user);
    gtag('set', 'user_properties', userProps);
  };

  React.useEffect(() => {
    trackView(location.pathname + location.search);
  }, [location]);

  React.useEffect(() => {
    if(consentCookie) {
      const consent = consentCookie.split(',');
      if(consent.includes('analytics')) {
        injectScript();
      }

    } else {
      modal.showConsent().then(consent => {
        Cookies.set('gdpr-consent', consent.join(','), { expires: 365 });
      });
    }
  }, [consentCookie]);

  return (
    <context.Provider value={{ trackUser, trackEvent }}>
      {children}
    </context.Provider>
  )
}