///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import * as theme from 'src/shared/theme';
import useQueryParam from 'src/shared/lib/useQueryParam';
import { getProviderURL } from 'src/shared/lib/urls';
import FakeText from 'src/shared/components/FakeText';
import { Layout, Sidebar, Search, Section, Footer } from 'src/app/components/Layout';
import RichText from 'src/shared/components/RichText';
import BlankSlate from 'src/app/components/BlankSlate';
import Flag from 'src/app/components/Flag';

const GET_PROVIDERS = gql`
  query($search: String!) {
    searchProviders(search: $search) {
      id
      name
      logo_url
      excerpt
    }
  }
`;

export default function Providers() {
  const [search = '', setSearch] = useQueryParam('search');
  const { data: { searchProviders: providers } = {} } = useQuery(GET_PROVIDERS, { variables: { search } });

  return (
    <Layout>
      <Sidebar>
        <Section name="Search">
          <Search value={search} onChange={e => setSearch(e.target.value)} />
        </Section>
      </Sidebar>
      <Listing>
        {(() => {
          if(!providers) {
            return (
              <ProvidersWrapper>
                {Array(10).fill().map((_, i) => (
                  <FakeProvider key={i} as="div">
                    <LogoOuter>
                      <LogoInner>
                        <FakeLogo />
                      </LogoInner>
                    </LogoOuter>
                    <ProviderName><FakeText width="50%" /></ProviderName>
                    <ProviderExcerpt>
                      <FakeText opacity={0.25} />
                      <FakeText opacity={0.25} />
                    </ProviderExcerpt>
                    <FakeButton><FakeText opacity={0.25} /></FakeButton>
                  </FakeProvider>
                ))}
              </ProvidersWrapper>
            );
          }

          if(providers.length === 0) {
            return <BlankSlate type="providers" />;
          }

          return (
            <ProvidersWrapper>
              {providers.map(provider => (
                <Provider key={provider.id} to={getProviderURL(provider)}>
                  {provider.is_hidden && (
                    <FlagFix>
                      <Flag>not publicly visible</Flag>
                    </FlagFix>
                  )}
                  <LogoOuter>
                    <LogoInner>
                      <ProviderLogo src={provider.logo_url} />
                    </LogoInner>
                  </LogoOuter>
                  <ProviderName>{provider.name}</ProviderName>
                  <ProviderExcerpt><RichText>{provider.excerpt}</RichText></ProviderExcerpt>
                  <Button>Read More</Button>
                  <HoverOverlay />
                </Provider>
              ))}
            </ProvidersWrapper>
          );
        })()}
        <Footer />
      </Listing>
    </Layout>
  );
};

const Listing = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ProvidersWrapper = styled.div`
  background: url(/bg4.png) #f3f3f3;
  padding: 10px 0 0 10px;
  flex-shrink: 0;
  ${theme.above_mobile} {
    padding: 20px 10px 0 20px;
  }
  @supports (display: grid) {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    padding: 10px 10px;

    ${theme.above_mobile} {
      padding: 20px 20px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1366px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1500px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
`;

const HoverOverlay = styled.div`
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
`;

const Provider = styled(NavLink)`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  color: inherit;
  vertical-align: top;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  width: calc(100% - 10px);
  cursor: pointer;
  &:hover ${HoverOverlay} {
    display: block;
  }
  ${theme.above_mobile} {
    display: inline-flex;
    margin: 0 10px 10px 0;
    width: calc(50% - 10px);
  }
  @media (min-width: 1024px) {
    width: calc(33.33% - 10px);
  }
  @media (min-width: 1366px) {
    width: calc(25% - 10px);
  }
  @media (min-width: 1500px) {
    width: calc(20% - 10px);
  }
  @supports (display: grid) {
    width: 100% !important;
    margin: 0 !important;
  }
`;

const FakeProvider = styled(Provider)`
  > * {
    opacity: 0.5;
  }
`;

const ProviderName = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px;
  text-align: center;
`;

const LogoOuter = styled.div`
  position: relative;
  height: 0px;
  width: 100%;
  padding-top: calc((100% - 40px) / 2);
  margin: 20px auto;
  max-width: 180px;
  max-height: 90px;
`;

const LogoInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProviderLogo = styled.img`
  display: block;
  max-height: 100%;
  max-width: 100%;
  image-rendering: -webkit-optimize-contrast;
`;

const FakeLogo = styled.div`
  display: block;
  height: 80px;
  width: 100%;
`;

const ProviderExcerpt = styled.div`
  flex-grow: 1;
  text-align: center;
  margin-bottom: 10px;
`;

const Button = styled.div`
  border: none;
  display: block;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  text-align: center;
  margin-top: 10px;
  text-decoration: none;
  background: ${theme.brand_primary};
  &:hover {
    background: ${theme.brand_primary_border};
  }
`;

const FakeButton = styled.div`
  display: block;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  text-align: center;
  margin-top: 10px;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.25);
`;

const FlagFix = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;