///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import '@babel/polyfill';
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { startBugsnagClientSide } from 'src/shared/lib/bugsnag';
import { ClientProvider } from './contexts/iso';
import App from './app';

startBugsnagClientSide();

hydrate((
  <BrowserRouter>
    <ClientProvider>
      <App />
    </ClientProvider>
  </BrowserRouter>
), document.getElementById('root'));
