///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import * as R from 'ramda';

const toggleInList = R.curry((value, list) => {
  const index = list.indexOf(value);
  return index >= 0 ? R.remove(index, 1, list) : R.append(value, list);
});

export default toggleInList;
