///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { PrimaryButton } from 'src/app/components/Button';
import * as theme from 'src/shared/theme';
import BasketContext from 'src/app/contexts/basket';
import useClickOutsideHandler from 'src/shared/lib/useClickOutsideHandler';
import Item from './Item';

export default function Basket() {
  const ref = React.useRef();
  const { isVisible, toggleVisibility, remove, show, hide, products } = React.useContext(BasketContext);

  useClickOutsideHandler(ref.current, (e) => {
    if(isVisible) {
      hide();
    }
  }, [isVisible]);

  return (
    <Container ref={ref} onMouseEnter={show} onMouseLeave={hide}>
      <Header onClick={toggleVisibility} open={isVisible}>
        <Inner open={isVisible}>
          <Count>{products && products.length}</Count>
          <Icon><i className="fa fa-shopping-cart" /></Icon>
        </Inner>
      </Header>
      {products && (
        <Contents visible={isVisible}>
          {products.length === 0 ? (
            <Placeholder>Your basket is currently empty</Placeholder>
          ) : (
            <>
              <Title>Your Basket</Title>
              <Items>
                {products.map(product => (
                  <Item key={product.id} product={product} onRemove={() => remove(product)} />
                ))}
              </Items>
              <Footer>
                <PrimaryButton as={NavLink} to="/catalogue/checkout">Submit Requests</PrimaryButton>
              </Footer>
            </>
          )}
        </Contents>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Header = styled.div`
  ${({ open }) => open && `
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.25);
  `}
`;

const Inner = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  line-height: 22px;
  height: 22px;
  cursor: pointer;
  ${({ open }) => open && `
    background: url(/bg4.png) #f3f3f3;
    color: black;
    position: relative;
    z-index: 21;
  `}
`;

const Count = styled.div`
  margin-right: 10px;
  font-size: 16px;
`;

const Icon = styled.div`
  font-size: 16px;
`;

const Contents = styled.div`
  display: none;
  z-index: 20;
  background: url(/bg4.png) #f3f3f3;
  color: black;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: opacity 0.25s ease;
  min-width: 350px;
  padding: 10px;
  position: absolute;
  top: 100%;
  left: 0;

  ${theme.above_mobile} {
    left: auto;
    right: 0;
  }

  ${({ visible }) => visible && `
    display: block;
    opacity: 1;
  `}
`;

const Items = styled.div``;

const Placeholder = styled.div`
  padding: 20px;
  text-align: center;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: normal;
  margin: 0 0 10px;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;