///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';

export default function useCache(value) {
  const [cache, setCache] = React.useState(value);

  React.useEffect(() => {
    if(value) {
      setCache(value);
    }
  }, [value]);

  return cache;
}