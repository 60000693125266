///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import * as theme from 'src/shared/theme';
import useQueryParams from 'src/shared/lib/useQueryParams';
import useCache from 'src/shared/lib/useCache';
import useDebounce from 'src/shared/lib/useDebounce';
import { Layout, Footer } from 'src/app/components/Layout';
import Products, { FakeProducts } from 'src/app/components/Products';
import Search from './Search';

const SEARCH_PRODUCTS = gql`
query($params: JSON) {
  searchProducts(params: $params) {
    products {
      id
      name
      excerpt
      is_hidden
      has_sample

      provider {
        id
        name
        logo_url
        is_hidden
      }
    }
    buckets
  }
}
`;

export default function Directory() {
  const ref = React.useRef();
  const [{ search, ...facets }] = useQueryParams();
  const debouncedSearch = useDebounce(search, 250);
  const debouncedFacets = React.useMemo(() => {
    return { search: debouncedSearch, ...facets };
  }, [debouncedSearch, JSON.stringify(facets)]);

  const { data: { searchProducts } = {}, loading } = useQuery(SEARCH_PRODUCTS, { variables: { params: debouncedFacets } });
  const buckets = useCache(searchProducts?.buckets);

  React.useEffect(() => {
    if(ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [facets]);

  return (
    <Layout>
      <Search buckets={buckets} />
      <Column>
        <Listing ref={ref}>
          {loading ? (
              <FakeProducts products={20} />
            ) : (
              <Products products={searchProducts.products} />
          )}
        </Listing>
        <Footer />
      </Column>
    </Layout>
  )
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${theme.above_mobile} {
    overflow-y: auto;
  }
`;

const Listing = styled.div`
  flex-grow: 1;
`;