///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';
import modalContext from 'src/shared/contexts/modal';
import userContext from 'src/app/contexts/user';
import isoContext from 'src/app/contexts/iso';
import PasskeyModal from './PasskeyModal';
import SetupAppModal from './SetupAppModal';
import NavItem from '../NavItem';

function getName(user) {
  const name = [user.firstname, user.lastname];
  if(name.find(part => part && part.length > 0)) {
    return name.join(' ');
  } else {
    return 'Logged in';
  }
}

export default function Profile() {
  const history = useHistory();
  const { loginUrl, logout } = React.useContext(isoContext);
  const { showModal } = React.useContext(modalContext);
  const { user, loading } = React.useContext(userContext);

  const showMyPasskey = () => {
    showModal(<PasskeyModal />);
  }

  const setupApp = () => {
    showModal(<SetupAppModal />);
  }

  const showDownloads = () => {
    history.push('/downloads');
  }

  const doLogout = () => {
    logout();
  }

  if(loading) {
    return null;
  }

  if(user) {
    return (
      <Container>
        <Header>
          <Inner>
            <Username>{getName(user)}</Username>
            <UserToggle><i className="fa fa-chevron-down" /></UserToggle>
          </Inner>
        </Header>
        <ContextMenu>
          {user.can_download && (<ContextItem onClick={showDownloads}>Downloads</ContextItem>)}
          <ContextItem onClick={showMyPasskey}>My Passkey</ContextItem>
          <ContextItem onClick={setupApp}>Setup MDX App</ContextItem>
          <ContextItem onClick={doLogout}>Logout</ContextItem>
        </ContextMenu>
      </Container>
    );

  } else {
    return <Login as="a" href={loginUrl} icon="fa fa-key">Login</Login>;
  }
};

const ContextMenu = styled.div`
  display: none;
  background: white;
  color: black;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 100;
`;

const ContextItem = styled.div`
  padding: 10px 15px;
  min-width: 200px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Header = styled.div``;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 22px;
  height: 22px;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  &:hover ${Header}{
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.25);
  }
  &:hover ${Inner} {
    background: white;
    color: black;
    position: relative;
    z-index: 101;
  }
  &:hover ${ContextMenu} {
    display: block !important;
  }
`;

const Username = styled.div`
  display: block;
  flex-grow: 1;
`;

const UserToggle = styled.div`
  margin-left: 20px;
  font-weight: normal;
  font-size: 12px;
`;

const Login = styled(NavItem)`
box-sizing: border-box;
padding: 10px 20px;
font-size: 16px;
line-height: 25px;
height: 42px;
text-decoration: none;
cursor: pointer;
border-radius: none;
color: white;
display: flex;
flex-direction: row;
align-items: center;
white-space: nowrap;
flex-shrink: 0;
background: transparent;
border: none;
&:hover {
  color: rgba(255, 255, 255, 0.7);
}
&.is-active {
  background: url(/bg4.png) #f3f3f3;
  border-bottom: none;
  color: black;
}
${theme.above_mobile} {
  border-bottom: none;
}
  color: black;
  text-decoration: none;
  ${theme.above_mobile} {
    color: white;
  }
`;