///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';
import FakeText from 'src/shared/components/FakeText';
import Checkbox from './Checkbox';

export { Checkbox };

export const Grid = styled.table`
  width: 100%;
  margin: 0;
  ${({ size }) => {
    if(size === 'small') {
      return `
        ${ColumnHeader} {
          padding: 4px 6px;
        }
        ${Cell} {
          padding: 4px 6px;
        }
      `;
    }
  }}
`;

export const ColumnHeader = styled.th`
  text-align: left;
  padding: 8px 10px;
  background: ${theme.brand_primary};
  border: 1px solid ${theme.brand_primary_border};
  border-left: none;
  &:first-child {
    border-left: 1px solid ${theme.brand_primary_border};
  }
  ${({ align }) => align && `
    text-align: ${align};
  `}
`;

export const Row = styled.tr`
  ${({ onClick }) => onClick && `
    cursor: pointer;
    transform: scale(1);
    &:hover:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.15);
      pointer-events: none;
    }
  `}
  > td {
    background: #fff;
  }

  &:nth-child(2n) > td {
    background: ${theme.zebra_stripe};
  }
`;

export const Cell = styled.td`
  padding: 8px 10px;
  background: white;
  color: rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  &:first-child {
    border-left: 1px solid #ddd;
  }
  ${({ align }) => align && `
    text-align: ${align};
  `}
`;

export const PlaceholderCell = styled.td`
  padding: 20px 0;
  text-align: center;
  border: 1px solid #ddd;
  border-top: none;
`;

export function FakeRows({ rows, columns }) {
  return (
    <>
      {Array(rows).fill().map((_, i) => (
        <Row key={i}>
          {Array(columns).fill().map((_, j) => (
            <Cell key={j}><FakeText opacity={0.125} /></Cell>
          ))}
        </Row>
      ))}
    </>
  );
}

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  > * {
    margin-left: 10px !important;
    &:first-child {
      margin-left: 0 !important;
    }
  }
`;