///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import styled from 'styled-components';
import * as theme from 'src/shared/theme';

const Button = styled.button`
  display: block;
  background: #eee;
  font-family: inherit;
  cursor: pointer;
  text-align: center;
  ${({ bgColor }) => bgColor && `background: ${bgColor};`};
  border: none;
  ${({ size = 16 }) => `
  padding: ${size / 2}px ${size}px;
  font-size: ${size}px;
  `}
  text-decoration: none;
  color: inherit;
  margin-right: 10px;
  position: relative;
  white-space: nowrap;
  &:last-child {
    margin-right: 0;
  }
  ${({ disabled }) => disabled ?
  `
    background: #ddd !important;
    color: #666;
    cursor: default;
  ` : `
    &:hover:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
    }
  `}
  ${({ shadow }) => shadow && `
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  `}
`;

export const PrimaryButton = styled(Button)`
  background: ${theme.brand_primary};
`;

export const WhiteButton = styled(Button)`
  background: #fff;
`;

export const GreyButton = styled(Button)`
  background: #eee;
`;

export const ModalButton = styled(Button)`
  background: #ddd;
`;