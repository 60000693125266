///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { parseISO, compareAsc } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import isoContext from 'src/app/contexts/iso';
import Placeholder from 'src/app/components/Placeholder';
import { Footer } from 'src/app/components/Layout';
import SubscriptionList from './SubscriptionList';
import hasExpired from 'src/shared/lib/hasExpired';

const GET_SUBSCRIPTIONS_AND_REQUESTS = gql`
  query {
    mySubscriptions(showExpired: true) {
      id
      is_expired
      expires_at
      created_at

      product {
        __typename
        ... on Product {
          id
          name
          data_dictionary

          pages {
            id
            name
            ppd_tag
          }

          provider {
            id
            name
            logo_url
          }

          downloads {
            id
            name
            type
            path
          }
        }
        ... on Sample {
          id
          name

          provider {
            id
            name
            logo_url
          }

          pages {
            id
            name
            ppd_tag
          }

          parent {
            id
            name
            data_dictionary
          }

          downloads {
            id
            name
            type
            path
          }
        }
      }
    }
    myAccessRequests(status: pending) {
      __typename
      ... on QuoteRequest {
        id
        product {
          name
          provider {
            name
            id
          }
        }
      }
      ... on SampleRequest {
        id
        product {
          id
          name

          parent {
            id
            name
          }

          provider {
            name
            id
            logo_url
          }
        }
      }
    }
  }
`;

export default function Subscriptions() {
  const history = useHistory();
  const { mode, loginUrl, loggedIn } = React.useContext(isoContext);
  const { type, recordtype } = useParams();
  const { data: { mySubscriptions: subscriptions, myAccessRequests: requests } = {} } = useQuery(GET_SUBSCRIPTIONS_AND_REQUESTS);

  const selectTab = (type) => {
    history.push(`/subscriptions/${type}`);
  };

  if(!loggedIn) {
    if(mode === 'client') {
      window.location.replace(loginUrl);
    }
    return null;
  }

  if(!(subscriptions && requests)) {
    return null;
  }

  const tabs = [];
  const requestedProducts = new Set(requests.map(req => req.product.id));
  const visibleSubscriptions = subscriptions.filter(sub => !hasExpired(sub) || !requestedProducts.has(sub.product.id));

  const combinedList = [
    ...requests.map(req => ({ ...req, type: 'request' })),
    ...visibleSubscriptions.map(sub => ({ ...sub, type: 'subscription' })),
  ]
    .sort((a, b) => compareAsc(parseISO(a.created_at), parseISO(b.created_at)))

  const liveItems = combinedList.filter(sub => !sub.product.parent);
  const sampleItems = combinedList.filter(sub => sub.product.parent);

  if(liveItems.length > 0) {
    tabs.push({
      id: 'live',
      label: 'Live Data',
      render: () => <SubscriptionList recordtype={recordtype} items={liveItems} />,
    });
  }

  if(sampleItems.length > 0) {
    tabs.push({
      id: 'samples',
      label: 'Sample Data',
      render: () => <SubscriptionList recordtype={recordtype} items={sampleItems} />,
    });
  }

  if(!type && tabs.length > 0) {
    history.replace(`/subscriptions/${tabs[0].id}`);
    return null;
  }

  const page = tabs.find(tab => tab.id === type) || (tabs.length > 0 ? tabs[0] : null);

  return (
    <Container>
      <Header>
        <Title>My Subscriptions</Title>
        {tabs.length > 0 && (
          <Tabs>
            {tabs.map(option => (
              <Tab
                key={option.id}
                selected={option.id === type}
                onClick={() => selectTab(option.id)}
              >{option.label}</Tab>
            ))}
          </Tabs>
        )}
      </Header>
      <PaneShadow>
        <Pane>
          {page ? page.render() : (
            <>
              <Placeholder message="You don't currently have any subscriptions." />
              <Footer />
            </>
          )}
        </Pane>
      </PaneShadow>
    </Container>
  );
}

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.header`
  padding: 20px 0 0;
`;

const Title = styled.h1`
  font-size: 24px;
  margin: 0 20px 20px;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
`;

const Tab = styled.div`
  display: inline-flex;
  padding: 10px 20px;
  ${({ selected }) => selected && `
    background-color: #fff !important;
    cursor: default !important;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.125);
  `}
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Pane = styled.div`
  position: relative;
  z-index: 2;
  background: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PaneShadow = styled.div`
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.125);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;