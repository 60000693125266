///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { ModalButton } from 'src/app/components/Button';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';
import Consent from '../components/Consent';
import ConfirmDialog from '../components/ConfirmDialog';

const ModalContext = React.createContext();

export default ModalContext;

export function ModalProvider({ children }) {
  const [modal, setModal] = React.useState(null);

  const closeModal = () => {
    setModal(null);
  };

  const showError = (body) => {
    setModal(
      <>
        <Header bgColor={theme.danger_border}>
          <Icon className="fa fa-times" />
          <Title>Something went wrong</Title>
        </Header>
        <Body>{body}</Body>
        <Actions>
          <ModalButton onClick={closeModal}>Close</ModalButton>
        </Actions>
      </>
    );
  };

  const showSuccess = (title, body) => {
    if(!body) {
      body = title;
      title = 'Operation succeeded';
    }
    
    setModal(
      <>
        <Header bgColor={theme.success_border}>
          <Icon className="fa fa-check" />
          <Title>{title}</Title>
        </Header>
        <Body>{body}</Body>
        <Actions>
          <ModalButton onClick={closeModal}>Close</ModalButton>
        </Actions>
      </>
    );
  };

  const showModal = (body) => {
    setModal(<Body>{body}</Body>);
  }

  const showConfirmDialog = (prompt, answer) => {
    return new Promise((resolve) => {
      setModal(
        <Body>
          <ConfirmDialog prompt={prompt} answer={answer} onProceed={() => resolve(true)} onCancel={() => resolve(false)} />
        </Body>
      );
    }).then(answer => {
      closeModal();
      return answer;
    });
  }

  const showQuestion = (body) => {
    return new Promise((resolve) => {
      setModal(
        <>
          <Body>{body}</Body>
          <Actions>
            <ModalButton onClick={() => resolve(true)}>Yes</ModalButton>
            <ModalButton onClick={() => resolve(false)}>No</ModalButton>
          </Actions>
        </>
      );
    }).then(answer => {
      closeModal();
      return answer;
    });
  }

  const showConsent = () => {
    return new Promise((resolve) => {
      setModal(<Body><Consent onConsent={resolve} /></Body>);
    }).then(answer => {
      closeModal();
      return answer;
    });
  };

  const context = { closeModal, showError, showSuccess, showQuestion, showModal, showConsent, showConfirmDialog };

  if(typeof window !== 'undefined') {
    window.modal = context;
  }

  return (
    <ModalContext.Provider value={context}>
      <>
        {modal && (
          <ModalShadow data-testid="modal-shadow">
            <ModalWindow data-testid="modal">
              {modal}
            </ModalWindow>
          </ModalShadow>
        )}
        {children}
      </>
    </ModalContext.Provider>
  );
}

const ModalShadow = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalWindow = styled.div`
  background: #fff;
  min-width: 400px;
  margin: auto auto;
  overflow: auto;
`;

const Header = styled.header`
  padding: 10px 10px 10px;
  background: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.i`
  font-size: 20px;
  color: white;
  display: block;
`;

const Title = styled.h2`
  font-size: 16px;
  color: white;
  font-weight: normal;
  margin: 0;
  margin-top: -5px;
  margin-left: 10px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
`;

const Body = styled.div`
  position: relative;
  padding: 10px 10px 10px;
`;

const Actions = styled.footer`
  padding: 10px;
  display: flex;
  flex-direction: row;
  > * {
    flex-grow: 1;
  }
`;
