///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

export default function apolloClient(token, ssrMode = false) {
  let link = createUploadLink({
    uri: '/graphql',
    fetch,
  });

  if(token) {
    const authLink = setContext((_, { headers }) => ({
      headers: { ...headers, authorization: `Bearer ${token}`, }
    }));

    link = authLink.concat(link);
  }

  const client = new ApolloClient({
    ssrMode,
    cache: new InMemoryCache(),
    link,
  });

  return client;
}
