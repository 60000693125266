///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { BugsnagBoundary } from 'src/shared/lib/bugsnag';
import { ModalProvider } from './modal';
import { AnalyticsProvider } from './analytics';

export default function GlobalProvider({ children }) {
  return (
    <BugsnagBoundary>
      <ModalProvider>
        <AnalyticsProvider>
          {children}
        </AnalyticsProvider>
      </ModalProvider>
    </BugsnagBoundary>
  );
}