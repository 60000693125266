///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import userContext from 'src/app/contexts/user';
import { NavLink } from 'react-router-dom';
import * as theme from 'src/shared/theme';
import NavItem from './NavItem';
import Basket from './Basket';
import Profile from './Profile';
import InactiveBanner from './InactiveBanner';

export default function LayoutHeader() {
  const { user } = React.useContext(userContext);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const closeMenu = () => setMenuOpen(false);

  return (
    <>
      {user && user.status === 'pending' && (
        <InactiveBanner />
      )}
      <Header>
        <MenuToggle onClick={() => setMenuOpen(!menuOpen)}><i className="fa fa-bars" /></MenuToggle>
        <Identity>
          <Branding>
            <BrandingInner>
              <Navigation>
                <LogoWrapper href="/catalogue">
                  <Logo src="/img/logo-wy.png" alt="MDX Technology" />
                </LogoWrapper>
              </Navigation>
            </BrandingInner>
          </Branding>
          <Subtitle><SubtitleLink to="/catalogue">Data Marketplace</SubtitleLink></Subtitle>
        </Identity>
        <Menu>
          <Nav isOpen={menuOpen}>
            <NavItem to="/catalogue/products" onClick={closeMenu} icon="fas fa-search">Browse</NavItem>
            <NavItem to="/catalogue/providers" onClick={closeMenu} icon="fa fa-upload">Data Providers</NavItem>
            <NavItem to="/subscriptions" onClick={closeMenu} icon="fa fa-stream">My Subscriptions</NavItem>
          </Nav>
          <UserMenu>
            <Basket />
            <Profile />
          </UserMenu>
        </Menu>
      </Header>
    </>
  );
};

const Header = styled.header`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  background: #000;
  ${theme.above_mobile} {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const Identity = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${theme.above_mobile} {
    width: 450px;
  }
`;

const Branding = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
`;

const BrandingInner = styled.div`
  padding: 15px 15px;
  @media (min-width: 980px) {
    width: 80%;
    margin: auto;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LogoWrapper = styled.a`
  text-decoration: none;
  display: block;
`;

const Subtitle = styled.h1`
  margin: 0;
  font-weight: normal;
  font-size: 20px;
`;

const SubtitleLink = styled(NavLink)`
  margin: 0;
  text-decoration: none;
  color: white;
  white-space: nowrap;
  align-self: center;
  line-height: 35px;
  height: 35px;
  padding: 10px 0;
  margin-left: 10px;
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Logo = styled.img`
  display: block;
  width: 109px;
  height: 45px;
`;

const Menu = styled.div`
  flex-grow: 1;
  flex-direction: column;
  align-self: stretch;
  color: white;
  ${theme.above_mobile} {
    width: 80%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    background: none;
    box-shadow: none;
    padding: 0 20px;
  }
`;

const MenuToggle = styled.button`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 45px;
  width: 45px;
  color: ${theme.brand_primary};
  text-align: center;
  line-height: 45px;
  margin: 15px;
  background: none;
  border: none;
  font-size: 18px;
  ${theme.above_mobile} {
    display: none;
  }
`;

const Nav = styled.nav`
  display: none;
  ${({ isOpen }) => isOpen && 'display: flex;'}
  flex-direction: column;
  flex-grow: 1;
  background: black;
  color: white;
  ${theme.above_mobile} {
    flex-direction: row;
    display: flex;
  }
`;

const UserMenu = styled.div`
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 11;
  color: black;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;

  ${theme.above_mobile} {
    background: transparent;
    color: inherit;
    justify-content: flex-end;
    box-shadow: none;
  }
`;
