///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { Input } from 'src/admin/components/ModalForm';
import { PrimaryButton, ModalButton } from 'src/app/components/Button';

export default function ConfirmDialog({ answer, prompt, onProceed, onCancel }) {
  const [value, setValue] = React.useState('');
  return (
    <Container>
      <Prompt>{prompt}</Prompt>
      <Input type="text" autoFocus modifier="outlined" value={value} onChange={e => setValue(e.target.value)} />
      <Actions>
        <PrimaryButton disabled={value !== answer} onClick={onProceed}>Confirm</PrimaryButton>
        <ModalButton onClick={onCancel}>Cancel</ModalButton>
      </Actions>
    </Container>
  )
}

const Container = styled.div``;

const Prompt = styled.p`
  margin: 0 0 20px;
`;

const Actions = styled.footer`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  > * {
    flex-grow: 1;
  }
`;
