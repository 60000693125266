///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

module.exports = function slugify(name, separator = '_') {
  return name
    .toLowerCase()
    .replace(/^[^a-z0-9]*(.*?)[^a-z0-9]*$/g, '$1') // remove leading and trailing nonword chars
    .replace(/'/g, '') // remove punctuation
    .replace(/[^a-z0-9]+/g, separator); // replace interstitial nonword chars with separator
}