///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import { chain } from 'ramda';
const slugify = require('src/shared/lib/slugify');

function mergeValueCounts(values, bucket) {
  return values.flatMap(value => {
    const valueIndex = value.id || 'any'; // for the 'Any' option prepended to single-select lists
    if(valueIndex in bucket) {
      value.count = bucket[valueIndex];
      return [value];

    } else {
      return [];
    }
  });
}

const addAncestor = parent => child =>{
  return { ...child, ancestors: [parent, ...child.ancestors] }
}

// sorts an unordered list of categories into a flattened tree,
// with `ancestors` being a list of the category's ancestors
function sortValues(categories, parentId) {
  return chain(
    cat => ([
      { ...cat, ancestors: [] },
      ...(sortValues(categories, cat.id).map(addAncestor(cat)))
    ]),
    categories.filter(cat => cat.parent_id === parentId)
  );
}

export default function constructFacets(facetOptions, buckets) {
  let facets = facetOptions.map(facet => {
    let values = facet.values.slice();
    if(!facet.is_multi) {
      values.unshift({
        id: undefined,
        facet_id: facet.id,
        parent_id: null,
        label: 'Any',
      });
    }

    values = sortValues(values, null);

    if(buckets) {
      const bucket = buckets[slugify(facet.name)];
      if(bucket) {
        values = mergeValueCounts(values, bucket);
      }
    }

    return { ...facet, values };
  });

  facets.sort((a, b) => a.sort_order - b.sort_order);
  return facets;
}