///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { gql, useQuery } from '@apollo/client';
import * as theme from 'src/shared/theme';
import isoContext from 'src/app/contexts/iso';
import modalContext from 'src/shared/contexts/modal';

const GET_ME = gql`
  query {
    me {
      username
      connect_passkey
    }
  }
`;

export default function SetupAppModal() {
  const { closeModal } = React.useContext(modalContext);
  const { connectUrl } = React.useContext(isoContext);
  const { data: { me: user } = {} } = useQuery(GET_ME);

  if(!user) {
    return null;
  }

  const username = encodeURIComponent(user.username);
  const passkey = encodeURIComponent(user.connect_passkey);
  const server = encodeURIComponent(connectUrl);

  const installUrl = 'https://apps.apple.com/gb/app/iowarocks/id1520712204';
  const configureUrl = `iowarocks://credentials?u=${username}&p=${passkey}&s=${server}`;

  return (
    <>
      <CloseButton onClick={closeModal}><i className="fa fa-times" /></CloseButton>
      <Container>
        <Column>
          <Title>Step 1. Install The App</Title>
          <P>Scan the QR code below or <a href="https://apps.apple.com/gb/app/iowarocks/id1520712204" target="_blank">visit the app store</a> to install the IOWArocks iOS app.</P>
          <StyledQRCode value={installUrl} size={256} />
        </Column>
        <Column>
          <Title>Step 2. Configure Your Account</Title>
          <P>Scan the QR code below to set up the iOS app with your MDX Marketplace account.</P>
          <StyledQRCode value={configureUrl} size={256} />
        </Column>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${theme.above_mobile} {
    flex-direction: row;
    width: 100%;
    max-width: 880px;
  }
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 20px;
  padding: 0;
  cursor: pointer;
`;

const Column = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 20px 0;
  padding: 0 20px;
  ${theme.above_mobile} {
    width: 50%;
    &:last-child {
      border-left: 1px solid #bbb;
      margin-left: -1px;
    }
  }
`;

const Title = styled.h2`
  font-size: 24px;
  margin: 0 0 10px;
`;

const P = styled.p`
  padding: 0;
  margin: 0 0 10px;
`;

const StyledQRCode = styled(QRCode)`
  display: block;
  margin: 30px auto 20px;
`;