///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import useLocalStorage from 'src/shared/lib/useLocalStorage';

const GET_PRODUCTS = gql`
  query($params: JSON) {
    searchProducts(params: $params) {
      products {
        id
        name

        provider {
          id
          name
        }
      }
    }
  }
`;

const BasketContext = React.createContext();

export default BasketContext;

export function BasketProvider({ children }) {
  const client = useApolloClient();
  const [contents = [], setContents] = useLocalStorage('basket', { json: true });
  const [isVisible, setVisible] = React.useState(false);
  const [products, setProducts] = React.useState(null);
  const loading = products === null;

  React.useEffect(() => {
    if(contents.length > 0) {
      const params = { id: contents };
      client.query({
        query: GET_PRODUCTS,
        variables: { params },
      }).then(({ data }) => {
        const { searchProducts } = data;
        setProducts(searchProducts.products);
      });

    } else {
      setProducts([]);
    }
  }, [JSON.stringify(contents)]);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const toggleVisibility = () => setVisible(!isVisible);

  const add = (product) => {
    if(!contents.includes(product.id)) {
      setContents([...contents, product.id]);
    }

    show();
  }

  const remove = (product) => {
    setContents(contents.filter(id => id != product.id));
  }

  const empty = () => {
    setContents([]);
  }

  const context = { isVisible, products, loading, show, hide, toggleVisibility, add, remove, empty };

  return (
    <BasketContext.Provider value={context}>
      {children}
    </BasketContext.Provider>
  );
}