///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';
import Section from './Section';
import Footer from './Footer';

export { Section, Footer };

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${theme.above_mobile} {
    flex-direction: row;
    overflow: hidden;
  }
`;

export const Sidebar = styled.aside`
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  background: white;
  ${theme.above_mobile} {
    width: 400px;
    max-width: 35%;
    overflow-y: auto;
  }
`;

export const Search = styled.input`
  box-sizing: border-box;
  height: 30px;
  display: block;
  font-size: 14px;
  font-weight: 300;
  padding: 0 0.5em;
  border-radius: 3px;
  border: 1px solid #999;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
`;
