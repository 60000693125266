///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';

export default function Filter({ value, onChange }) {
  return (
    <Container>
      <Icon><i className="fa fa-filter" /></Icon>
      <Input placeholder="Filter…" type="text" value={value} onChange={e => onChange(e.target.value)} />
      {value && (
        <ClearIcon onClick={() => onChange('')}><i className="fa fa-times" /></ClearIcon>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 30px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 3px;
  border: 1px solid #999;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  &:focus-within {
    outline: auto 5px -webkit-focus-ring-color;
  }
`;

const Icon = styled.div`
  opacity: 0.25;
  padding: 0 0 0 0.5em;
`;

const ClearIcon = styled.div`
  opacity: 0.25;
  padding: 0 0.5em 0 0;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const Input = styled.input`
  background: transparent;
  border: none;
  flex-grow: 1;
  height: 30px;
  display: block;
  font-size: 14px;
  font-weight: 300;
  padding: 0 0.5em;
  &:focus {
    outline: none;
  }
`;