///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import userContext from 'src/app/contexts/user';
import * as theme from 'src/shared/theme';

export default function InactiveBanner() {
  const { resendToken } = React.useContext(userContext);
  
  const handleResendToken = (event) => {
    event.preventDefault();
    resendToken();
  }

  return (
    <Container>
      <Message>
        Your account has not yet been verified, please check your email inbox for a
        verification link or <ResendLink onClick={handleResendToken}>click here</ResendLink> to resend the email.
      </Message>
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.warning};
  padding: 10px 10px;
`;

const Message = styled.p`
  margin: 0;
`;

const ResendLink = styled.a`
  color: inherit;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`;