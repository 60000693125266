///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { Document, Pt, Def } from 'src/app/components/Document';

export default function CookiePolicy() {
  return (
    <Document>
      <h1>MDX Technology Limited</h1>
      <h2>Cookie Policy</h2>
      <p>Our website (“Our Site”) uses Cookies and similar technologies in order to distinguish you from other users. By using
        Cookies, We are able to provide you with a better experience and to improve Our Site by better understanding how you use
        it. Please read this Cookie Policy carefully and ensure that you understand it. Your acceptance of Our Cookie Policy is
        deemed to occur if you continue using Our Site. If you do not agree to Our Cookie Policy, please stop using Our Site
        immediately.</p>

      <Pt i="1." as="h3">Definitions and Interpretation</Pt>

      <Pt i="1.1" as="div">
        <p>In this Cookie Policy, unless the context otherwise requires, the following expressions have the following
          meanings:</p>

        <Def i="“Cookie”">means a small file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or
            when you use certain features of Our Site;</Def>

        <Def i="“Cookie Law”">means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003 and of EU
            Regulation 2016/679 General Data Protection Regulation (“GDPR”) as amended from time to time;</Def>

        <Def i="“personal data”">means any and all data that relates to an identifiable person who can be directly or indirectly identified from
            that data, as defined by GDPR; and</Def>

        <Def i="“We/Us/Our”">means MDX Technology Limited, a limited company registered in England under company number 07305821, whose
            registered address is 2nd Floor Regis House, 45 King William Street, London, United Kingdom, EC4R 9AN. </Def>
      </Pt>

      <Pt i="2." as="h3">Information About Us</Pt>

      <Pt i="2.1.">Our Site is owned and operated by MDX Technology Limited, a limited company registered in England under
        company number 07305821 whose registered address is 2nd Floor Regis House, 45 King William Street, London, United
        Kingdom, EC4R 9AN.</Pt>

      <Pt i="2.2.">2.2	Questions regarding data protection should be emailed
        to <a href="mailto:data-protection@mdxtechnology.com">data-protection@mdxtechnology.com</a> or sent by post to
        ‘MDXT Data Protection’ at the address above. </Pt>

      <Pt i="3." as="h3">How Does Our Site Use Cookies?</Pt>

      <Pt i="3.1.">Our Site may place and access certain first party Cookies on your computer or device. First party Cookies
        are those placed directly by Us and are used only by Us. We use Cookies to facilitate and improve your experience of
        Our Site and to provide and improve Our services. We have carefully chosen these Cookies and have taken steps to ensure
        that your privacy and personal data is protected and respected at all times.</Pt>

      <Pt i="3.2.">By using Our Site, you may also receive certain third party Cookies on your computer or device. Third party
        Cookies are those placed by websites, services, and/or parties other than Us. Third party Cookies are used on Our Site
        for Google Analytics. For more details, please refer to section 4 below.</Pt>
      
      <Pt i="3.3." as="div">
        <p>All Cookies used by and on Our Site are used in accordance with current Cookie Law. We may use some or all of the
          following types of Cookie:</p>

        <Pt i="3.3.1." as="div">
          <h4>Strictly Necessary Cookies</h4>
          <p>A Cookie falls into this category if it is essential to the operation of Our Site, supporting functions such as
            logging in, your shopping basket, and payment transactions.</p>
        </Pt>

        <Pt i="3.3.2." as="div">
          <h4>Analytics Cookies</h4>
          <p>It is important for Us to understand how you use Our Site, for example, how efficiently you are able to navigate
            around it, and what features you use. Analytics Cookies enable us to gather this information, helping Us to
            improve Our Site and your experience of it.</p>
        </Pt>

        <Pt i="3.3.3." as="div">
          <h4>Functionality Cookies</h4>
          <p>Functionality Cookies enable Us to provide additional functions to you on Our Site such as personalisation and
            remembering your saved preferences. Some functionality Cookies may also be strictly necessary Cookies, but not all
            necessarily fall into that category.</p>
        </Pt>

        <Pt i="3.3.4." as="div">
          <h4>Targeting Cookies</h4>
          <p>It is important for Us to know when and how often you visit Our Site, and which parts of it you have used
            (including which pages you have visited and which links you have visited). As with analytics Cookies, this
            information helps us to better understand you and, in turn, to make Our Site and advertising more relevant to
            your interests. </p>
        </Pt>

        <Pt i="3.3.5." as="div">
          <h4>Third Party Cookies</h4>
          <p>Third party Cookies are not placed by Us; instead, they are placed by third parties that provide services to Us
            and/or to you. Third party Cookies may be used by advertising services to serve up tailored advertising to you on
            Our Site, or by third parties providing analytics services to Us (these Cookies will work in the same way as
            analytics Cookies described above).</p>
        </Pt>

        <Pt i="3.3.6." as="div">
          <h4>Persistent Cookies</h4>
          <p>Any of the above types of Cookie may be a persistent Cookie. Persistent Cookies are those which remain on your
            computer or device for a predetermined period and are activated each time you visit Our Site.</p>
        </Pt>

        <Pt i="3.3.7." as="div">
          <h4>Session Cookies</h4>
          <p>Any of the above types of Cookie may be a session Cookie. Session Cookies are temporary and only remain on your
            computer or device from the point at which you visit Our Site until you close your browser. Session Cookies are
            deleted when you close your browser.</p>
        </Pt>
      </Pt>

      <Pt i="3.4.">Cookies on Our Site are not permanent and will expire based on the Google Analytics retention policy.
        Please refer to: <a href="http://www.google.com/intl/en/policies/privacy" target="_blank">http://www.google.com/intl/en/policies/privacy</a>.</Pt>

      <Pt i="3.5.">For more details of the personal data that We collect and use, the measures we have in place to protect
        personal data, your legal rights, and our legal obligations, please refer to our Privacy Policy
        at <a href="https://mdxtechnology.com/privacy-policy" target="_blank">https://mdxtechnology.com/privacy-policy</a>.</Pt>

      <Pt i="3.6.">For more specific details of the Cookies that We use, please refer to the table below.</Pt>

      <Pt i="4." as="h3">What Cookies Does Our Site Use?</Pt>

      <Pt i="4.1.">There are no first party Cookies placed on your computer or device. </Pt>

      <Pt i="4.2." as="div">
        <p>The following third party Cookies may be placed on your computer or device:</p>

        <table cellSpacing={0} cellPadding={0}>
          <thead>
            <tr>
              <th>Name of Cookie</th>
              <th>Purpose &amp; Type</th>
              <th>Provider</th>
              <th>Strictly Necessary</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>Google Analytics</b></td>
              <td>We use Google Analytics on this website. Google Analytics is a web analysis service
                provided by Google. Google utilises the data collected to track and examine the use of mdxtechnology.com, to
                prepare reports on its activities and share them with other Google services. Google may use the data
                collected to contextualise and personalise the ads of its own advertising network.</td>
              <td>Google</td>
              <td>Yes</td>
            </tr>
          </tbody>
        </table>
      </Pt>

      <Pt i="4.3.">Our Site may use an analytics services provided by Google Analytics. Website analytics refers to a set
        of tools used to collect and analyse anonymous usage information, enabling Us to better understand how Our Site is
        used. This, in turn, enables Us to improve Our Site and services offered through it. You do not have to allow Us to
        use these Cookies, however whilst Our use of them does not pose any risk to your privacy or your safe use of Our
        Site, it does enable Us to continually improve Our Site, making it a better and more useful experience for you.</Pt>

      <Pt i="4.4.">The analytics service(s) which may be used by Our Site will use analytics Cookies to gather information
        as detailed at: <a href="http://www.google.com/intl/en/policies/privacy" target="_blank">http://www.google.com/intl/en/policies/privacy</a>.</Pt>

      <Pt i="5." as="h3">Consent and Control</Pt>

      <Pt i="5.1.">You can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you
        to choose whether you wish to disable all Cookies or only third party Cookies. By default, most internet browsers accept
        Cookies but this can be changed. For further details, please consult the help menu in your internet browser or the
        documentation that came with your device.</Pt>

      <Pt i="5.2." as="div">
        <p>The links below provide instructions on how to control Cookies in all mainstream browsers:</p>

        <Pt i="5.2.1.">Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=en-GB" target="_blank">https://support.google.com/chrome/answer/95647?hl=en-GB</a></Pt>
        <Pt i="5.2.2.">Microsoft Internet Explorer: <a href="https://support.microsoft.com/en-us/kb/278835" target="_blank">https://support.microsoft.com/en-us/kb/278835</a></Pt>
        <Pt i="5.2.3.">Microsoft Edge: <a href="https://support.microsoft.com/en-gb/products/microsoft-edge" target="_blank">https://support.microsoft.com/en-gb/products/microsoft-edge</a> (Please note that there are no specific instructions at this time, but Microsoft support will be able to assist)</Pt>
        <Pt i="5.2.4.">Safari (macOS): <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target="_blank">https://support.apple.com/en-gb/guide/safari/sfri11471/mac</a></Pt>
        <Pt i="5.2.5.">Safari (iOS): <a href="https://support.apple.com/en-gb/HT201265" target="_blank">https://support.apple.com/en-gb/HT201265</a></Pt>
        <Pt i="5.2.6.">Mozilla Firefox: <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences" target="_blank">https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences</a></Pt>
        <Pt i="5.2.7.">Android: <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en" target="_blank">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en</a> (Please refer to your device’s documentation for manufacturers’ own browsers)</Pt>
      </Pt>

      <Pt i="6." as="h3">Changes to this Cookie Policy</Pt>

      <Pt i="6.1.">	We may alter this Cookie Policy at any time. Any such changes will become binding on you on your first use of
        Our Site after the changes have been made.  You are therefore advised to check this page from time to time.</Pt>

      <Pt i="6.2.">In the event of any conflict between the current version of this Cookie Policy and any previous version(s), the
        provisions current and in effect shall prevail unless it is expressly stated otherwise.</Pt>

      <Pt i="7." as="h3">Further Information</Pt>

      <Pt i="7.1.">If you would like to know more about how We use Cookies, please contact us at <a href="mailto:data-protection@mdxtechnology.com">data-protection@mdxtechnology.com</a>, or
        by post at 2nd Floor Regis House, 45 King William Street, London, United Kingdom, EC4R 9AN.</Pt>

      <Pt i="7.2.">For more information about privacy, data protection and our terms and conditions, please visit <a href="https://mdxtechnology.com/privacy-policy">https://mdxtechnology.com/privacy-policy</a>.</Pt>

      <h4>Version Date 25th March 2021</h4>
    </Document>
  );
}
