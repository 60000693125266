///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import * as theme from 'src/shared/theme';
import { getProductURL } from 'src/shared/lib/urls';

export default function BasketItem({ product, onRemove }) {
  const [hover, setHover] = React.useState(false);
  const [removeHover, setRemoveHover] = React.useState(false);
  return (
    <Container onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} highlight={hover && !removeHover}>
      <Description to={getProductURL(product)}>
        <Name>{product.name}</Name>
        <Provider>Provided By <ProviderName>{product.provider.name}</ProviderName></Provider>
      </Description>
      <Actions>
        <RemoveButton type="button" onMouseEnter={() => setRemoveHover(true)} onMouseLeave={() => setRemoveHover(false)} onClick={onRemove}><i className="fa fa-times" /></RemoveButton>
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ddd;
  border-top: none;
  background: white;
  &:first-child {
    border-top: 1px solid #ddd;
  }
  ${({ highlight }) => highlight && `
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    pointer-events: none;
  }`}
`;

const Description = styled(NavLink)`
  flex-grow: 1;
  padding: 10px;
  white-space: nowrap;
  text-decoration: none;
  color: inherit;
`;

const Actions = styled.div``;

const RemoveButton = styled.button`
  border: none;
  background: transparent;
  padding: 20px 25px;
  color: ${theme.danger};
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: ${theme.danger};
    color: white;
  }
`;

const Name = styled.div`
  font-weight: bold;
`;

const Provider = styled.div``;

const ProviderName = styled.strong`
  font-weight: bold;
`;
