///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { PrimaryButton, WhiteButton } from 'src/app/components/Button';
import Placeholder from 'src/app/components/Placeholder';
import { Section, ProductDownload, ProductPage } from 'src/app/modules/Subscription';

export default function ProductSampleData({ sample, hasSampleSubscription, sampleRequested, loggedIn, loginUrl, requestSample }) {
  if(!(sample && (sample.pages.length > 0 || sample.downloads.length > 0))) {
    return null;
  }

  if(hasSampleSubscription) {
    return (
      <>
        {sample.downloads.length > 0 && (
          <Section title="Downloads">
            {sample.downloads.map(download => <ProductDownload key={download.id} download={download} />)}
          </Section>
        )}
        {sample.pages.length > 0 && (
          <Section title="Pages">
            {sample.pages.map(page => <ProductPage key={page.id} page={page} />)}
          </Section>
        )}
      </>
    );

  } else if(sampleRequested) {
    return <Placeholder message="Thank you for your interest, we will get in touch with you shortly." />;

  } else if(loggedIn) {
    return (
      <Placeholder message="You do not currently have access to this product">
        <PrimaryButton onClick={requestSample}>Request Access</PrimaryButton>
      </Placeholder>
    );

  } else {
    return (
      <Placeholder message="Please sign in to access this data">
        <PrimaryButton as="a" href={loginUrl}>Login</PrimaryButton>
        <WhiteButton as="a" href="/register">Sign Up</WhiteButton>
      </Placeholder>
    );
  }
}
