///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';

const storage = typeof localStorage === 'undefined' ? ({
  getItem: () => '',
  setItem: () => {},
}) : localStorage;

export default function useLocalStorage(key, { json = false } = {}) {
  const value = storage.getItem(key) || undefined;
  const [cache, setCache] = React.useState(json && value ? JSON.parse(value) : value);

  const setValue = (value = '') => {
    storage.setItem(key, json ? JSON.stringify(value) : value);
    setCache(value);
  }

  return [cache, setValue];
}