///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import isoContext from 'src/app/contexts/iso';
import { Document, Pt } from 'src/app/components/Document';

export default function TermsOfUse() {
  const { appUrl } = React.useContext(isoContext);
  return (
    <Document>
      <h1>MDX Technology Limited</h1>
      <h2>MDXT Data Marketplace Terms of Use</h2>

      <Pt i="1." as="h3">TERMS OF USE</Pt>
      <Pt i="1.1.">These terms of use ("<b>Terms of Use</b>") govern access to and use of MDX Technology Limited’s data
        information platform at https://data.mdxtechnology.com/ (the "<b>MDXT Data Marketplace</b>").</Pt>
      <Pt i="1.2.">The MDXT Data Marketplace is owned and operated by MDX Technology Limited, a limited liability company
        registered in England and Wales with company number 07305821 (“<b>We</b>” or “<b>Us</b>”). We have our registered office at 2nd
        Floor Regis House, 45 King William Street, London, United Kingdom, EC4R 9AN.  We can be contacted at:
        <a href="mailto:contact@mdxtechnology.com">contact@mdxtechnology.com</a>.</Pt>
      <Pt i="1.3.">Your use and/or your company’s use ("<b>You</b>", "<b>Yours</b>", <b>“the company</b>” and <b>“the company’s</b>”)
        of the MDXT Data Marketplace shall be subject to these Terms of Use which shall apply to all users (both You and the company).  We and You are jointly
        referred to in this Agreement as the "<b>Parties</b>".</Pt>
      <Pt i="1.4.">Your use of the MDXT Data Marketplace is deemed as Your acceptance of the Terms of Use and constitutes a binding
        legal agreement between You and Us effective from when You click the "I Accept" button.</Pt>
      <Pt i="1.5.">In accepting these Terms of Use (or any modifications of them) You represent and warrant to Us that You
        are legally able to enter into enforceable contracts and, if You are entering into these Terms of Use on behalf of a company,
        partnership, limited liability company or other legal entity, You represent and warrant that You have the legal authority to
        bind that entity.  In the event that You do not agree to all of the Terms of Use, click the button labelled "I Do Not Accept",
        in which case You should not, and do not have Our permission to, access and use the MDXT Data Marketplace.</Pt>
      
      <Pt i="2." as="h3">ADDITIONAL TERMS</Pt>
      <Pt i="2.1." as="div">
        <p>These Terms of Use incorporate the following additional terms which also apply to Your use of the MDXT Data Marketplace:</p>
        <Pt i="a)">Our Privacy Policy <a href="/privacy-policy">{appUrl}/privacy-policy</a>; and</Pt>
        <Pt i="b)">Our Cookie Policy <a href="/cookie-policy">{appUrl}/cookie-policy</a></Pt>
      </Pt>
      
      <Pt i="3." as="h3">YOUR RIGHTS OF USE</Pt>
      <Pt i="3.1." as="div">
        <p>Once You have accepted the Terms of Use You have a non-exclusive, non-transferable right during the Term to access
          the MDXT Data Marketplace solely to browse company listings and promote and market the company information which is listed on the
          MDXT Data Marketplace, subject to:</p>

        <Pt i="a)">You entering the following information: company name; company logo; contact details; website address; a
          reasonably detailed description of Your business including marketing materials; a CSV data sample of Your data; and </Pt>
        <Pt i="b)">You responding to all reasonable requests for further information by Us and any potential content consumers
        ("<b>Content Consumers</b>") within a reasonable period of time,</Pt>

        <p>the information at a) and b) above being collectively "<b>Company Information</b>".</p>
      </Pt>

      <Pt i="4." as="h3">OUR/THIRD PARTY RIGHTS OF USE</Pt>
      <Pt i="4.1">By providing the Company Information You are granting Us and Content Consumers a non-exclusive, non-transferable
        right during the Term to store, host, use, process and manipulate the Company Information as it appears on the IOWA
        Platform.</Pt>

      <Pt i="5." as="h3">REMOVAL AND UPDATE OF PLATFORM OR INFORMATION</Pt>
      <Pt i="5.1.">We reserve the right to suspend or withdraw or restrict the availability of all or any part of the IOWA
        Platform for business and operational reasons.</Pt>
      <Pt i="5.2.">We reserve the right to remove all or any portion of the Company Information and We shall not be obligated
        to maintain or update the sample CSV data file provided by You.</Pt>
      
      <Pt i="6." as="h3">REGISTRATION, PASSWORDS AND IOWA PLATFORM ADMINISTRATION</Pt>
      <Pt i="6.1." as="div">
        <p>Access to the MDXT Data Marketplace will be protected by unique usernames and passwords ("<b>User IDs</b>").
          You are responsible for setting up and maintaining Your User ID. When You register to access the MDXT Data Marketplace, You agree
          to provide only true, accurate, current and complete information on all registration pages. You agree that We may use the
          information You provide in accordance with Our Privacy Policy. You are entirely responsible for maintaining the
          confidentiality of such passwords and of Your access (including, if applicable, the passwords of each of Your personnel
          accessing the MDXT Data Marketplace under these Terms of Use). You are responsible for all access to and use of the MDXT Data Marketplace
          through Your passwords. You agree to accept responsibility for all activities that occur under Your account or User IDs,
          and also agree not to sell, transfer or assign Your rights as a user. You agree to:</p>

        <Pt i="a)">immediately notify Us of any actual or suspected unauthorized use of Your password or account or any other
          breach of security; and </Pt>
        <Pt i="b)">ensure that You exit from Your account at the end of each session. </Pt>
      </Pt>

      <Pt i="7." as="h3">TERM AND TERMINATION</Pt>
      <Pt i="7.1.">These Terms of Use shall continue from the date of initial acceptance by You (the "<b>Term</b>") unless Your
        access to the MDXT Data Marketplace is terminated by You for any reason or by Us as a result of a breach by You, in which case
        We will immediately disable Your access to the MDXT Data Marketplace and to any services provided via the MDXT Data Marketplace. provisions
        in these Terms of Use relating to limits on liability, indemnification and use and protection of information, disclaimers
        of warranties as well as all other provisions for which survival is equitable or appropriate shall survive the termination
        of these Terms of Use. Upon termination or expiration of these Terms of Use, We will remove all Company Information from the
        MDXT Data Marketplace.</Pt>
      
      <Pt i="8." as="h3">SCOPE OF USE</Pt>
      <Pt i="8.1." as="div">
        <p>You may use the MDXT Data Marketplace solely for Your internal business purposes in accordance with these Terms of Use and not
          for the benefit of any third parties. In addition, You agree not to:</p>

        <Pt i="a)">restrict or inhibit any other visitor from using the MDXT Data Marketplace including, without limitation, by means of
          "hacking", "denial of service attacks" or defacing any portion of the MDXT Data Marketplace;</Pt>
        <Pt i="b)">use the MDXT Data Marketplace for any unlawful purpose;</Pt>
        <Pt i="c)">display, perform, send, receive or store any content that is obscene, inappropriate, offensive, or otherwise
          objectionable, even if the material or its dissemination is lawful;</Pt>
        <Pt i="d)">harass any person or advocate or encouraging violence of any kind against any person, entity or government;</Pt>
        <Pt i="e)">intentionally infringe, violate or misappropriate another's intellectual property rights;</Pt>
        <Pt i="f)">intentionally or negligently obtain unauthorized access to, or interfere by any means with, any user, system,
          network, service, or account, including evasion of filters or violation of the security or integrity of any network or
          system; or</Pt>
        <Pt i="g)">intentionally or negligently distribute computer viruses or malware of any kind or sending, receiving or
          supporting email messages that are unsolicited, deceptive, anonymous, excessively voluminous or that contain falsified
          identifying information, including spamming and phishing.</Pt>
      </Pt>

      <Pt i="9." as="h3">CONFIDENTIAL INFORMATION</Pt>
      <Pt i="9.1.">Both parties acknowledge that they each may obtain data and information relating to products (such as services,
        software, specifications and documentation) of the other party, or relating to the parties themselves, which is of a
        confidential and proprietary nature ("<b>Confidential Information</b>"). Confidential Information includes data that You have
        transmitted into, loaded into or stored in the MDXT Data Marketplace, the content contained in the MDXT Data Marketplace, any and all
        communications concerning Our or Your business and marketing strategies, including, but not limited to, employee and
        customer lists, customer profiles, project plans, design documents, product strategies and pricing data, research, advertising
        plans, leads and sources of supply, development activities, design and coding, interfaces with the MDXT Data Marketplace, anything
        provided by Us in connection with Our support efforts under this Agreement, including, without limitation, computer programs,
        technical drawings, algorithms, know-how, formulas, processes, ideas, inventions (whether patentable or not), schematics and
        other technical plans and other information of the Parties which by its nature can be reasonably expected to be proprietary
        and confidential, whether it is presented in oral, printed, written, graphic or photographic or other tangible form (including
        information received, stored or transmitted electronically) even though specific designation as Confidential Information has
        not been made.</Pt>

      <Pt i="9.2." as="div">
        <p><b>Non-use and Non-Disclosure</b>: The Parties recognize and agree that it is an essential part of the business
          arrangement between the Parties that: </p>

        <Pt i="a)">the MDXT Data Marketplace should be able to share the Company Information with third parties that contract with the IOWA
          Platform as consumers to access such Information;</Pt>
        <Pt i="b)">the Confidential Information of the other Party is critical to such Party’s business; and </Pt>
        <Pt i="c)">that neither Party would enter into these Terms of Use without assurance that such information and its value
          will be protected as provided in this Clause 9. </Pt>
      </Pt>

      <Pt i="9.3." as="div">
        <p>As a condition to being provided with any disclosure of or access to Confidential Information, the Party receiving the
          Confidential Information shall: </p>

        <Pt i="a)">not access or use, or permit the access or use of, Confidential Information other than as necessary to exercise
          its rights or perform its obligations under and in accordance with these Terms of Use, which contemplates, among other
          things, the sharing of Your data with parties that contract with the MDXT Data Marketplace as consumers to obtain the access to
          such data; </Pt>
        <Pt i="b)">agree to keep such information confidential; </Pt>
        <Pt i="c)">safeguard the Confidential Information from unauthorized use, access or disclosure using at least the degree of
          care it uses to protect its most sensitive information and in no event less than a reasonable degree of care; </Pt>
        <Pt i="d)">ensure the compliance with, and be responsible and liable for any of its officers, directors, employees, advisors
          and contractors non-compliance with, the terms of this clause; and</Pt>
        <Pt i="e)">notify the other Party in writing immediately of any unauthorized disclosure or use of the disclosing party’s
          Confidential Information and cooperate with the disclosing Party to protect the confidentiality and ownership of all
          Confidential Information, privacy rights and other rights therein.</Pt>
      </Pt>

      <Pt i="9.4." as="div">
        <p><b>Exclusions</b>: The obligations of confidentiality shall not apply to information that: </p>

        <Pt i="a)">entered the public domain except as a result of a Party’s breach of these Terms of Use; </Pt>
        <Pt i="b)">prior to disclosure hereunder was already in the receiving Party’s possession without restriction; </Pt>
        <Pt i="c)">subsequent to disclosure hereunder is obtained by the receiving party on a non-confidential basis from a third
          party who had the right to disclose such information; or </Pt>
        <Pt i="d)">was developed by the receiving Party without use of the Confidential Information.</Pt>
      </Pt>

      <Pt i="9.5."><b>Disclosure Required by Law</b>: Notwithstanding anything to the contrary herein, each Party may comply with an
        order from a court or other governmental body of competent jurisdiction and disclose the other Party’s Confidential Information
        in compliance with that order; provided however, that the such Party may, if legally allowed, give the other Party prior notice
        to such disclosure and fully cooperate with the other Party, at the other Party’s cost and expense, in seeking a protective
        order, confidential treatment, or taking other measures to oppose or limit such disclosure. Each Party must not release any
        more of the other Party’s Confidential Information than is reasonably necessary to comply with an applicable order.</Pt>
      
      <Pt i="9.6."><b>Injunctive Relief</b>: The Parties each acknowledge that the breach by either Party of this Clause 9 may cause
        irreparable harm to the non-breaching party for which there is no adequate remedy at law. Accordingly, the parties agree that
        the disclosing Party may be entitled to seek immediate and permanent injunctive relief from a court of competent jurisdiction
        in the event of any such breach or threatened breach by Company. Nothing contained herein will limit the disclosing Party’s
        right to any remedies at law, including the recovery of damages for breach of this Clause 9.</Pt>

      <Pt i="10." as="h3">INTELLECTUAL PROPERTY</Pt>

      <Pt i="10.1." as="div">
        <p>You acknowledge and agree that We are the sole and exclusive owner of all Intellectual Property Rights (as defined below)
          in the MDXT Data Marketplace and that We do not convey any proprietary interest in or to the said Intellectual Property Rights other
          than the rights expressly granted in these Terms of Use. You shall not copy, disclose, publish, display, or assign, transfer,
          sell, lend, sub-license or otherwise make available Our Intellectual Property or copies thereof to third parties, except as
          necessary to implement the MDXT Data Marketplace on Your website(s). The term "<b>Intellectual Property Rights</b>" means all intellectual
          property rights protectable by law anywhere throughout the world, including: </p>

        <Pt i="a)">any idea, design, concept, technique, methodology, process, invention or discovery, whether patentable or not, all
          patents, patent applications, certificates of invention and all continuations -in -part, extensions, renewals, divisions,
          re-issues and re-examinations relating thereto; </Pt>
        <Pt i="b)">any works of authorship or expression, whether or not copyrightable, including moral rights and copyrights
          recognized by domestic or foreign law, together with any renewal or extension thereof and all rights deriving there
          from; </Pt>
        <Pt i="c)">any logos, trademarks, service marks, trade names and trade dress, and all goodwill relating thereto; and </Pt>
        <Pt i="d)">any trade secrets, know-how, technology licenses, confidential information, shop rights and other intellectual
          property rights owned or claimed and embodied therein, or associated therewith, or similar rights protectable under any
          laws or international conventions throughout the world, and in each case including any improvements, enhancements or
          modifications to, or derivatives from, any of the foregoing, and the right to apply for registrations, certificates, or
          renewals with respect thereto and the right to prosecute, enforce, obtain damages relating to, settle or release any past,
          present, or future infringement thereof.</Pt>
      </Pt>

      <Pt i="10.2."><b>No Rights in Services</b>: You will not, directly or indirectly, reverse engineer, decompile, disassemble,
        or otherwise attempt to discover the source code, object code, or underlying structure, ideas, or algorithms of, or found at
        or through the MDXT Data Marketplace or any software, documentation, or data related to the MDXT Data Marketplace; remove any proprietary
        notices or labels from the MDXT Data Marketplace; modify, translate, or create derivative works based on the MDXT Data Marketplace; or copy,
        distribute, pledge, assign, or otherwise transfer or encumber rights to the MDXT Data Marketplace.</Pt>
      
      <Pt i="11." as="h3">REPRESENTATIONS, WARRANTIES AND COVENANTS; DISCLAIMERS AND REMEDIES</Pt>

      <Pt i="11.1." as="div">
        <p>You represent, warrant, and covenant that</p>

        <Pt i="a)">You will use the MDXT Data Marketplace in compliance with these Terms of Use and all laws, rules, and regulations
          applicable to the performance of Your obligations hereunder, including but not limited to, the policies and laws related
          to: (i) anti-bribery and corruption; (ii) the prevention of slavery; (iii) intellectual property and proprietary rights;
          (iv) spamming, privacy, obscenity, or defamation; </Pt>
        <Pt i="b)">the Company Information will not include any personally identifiable information or sensitive information; and</Pt>
        <Pt i="c)">none of the Company Information does or will infringe, violate, trespass or in any manner contravene or breach
          or constitute the unauthorized use or misappropriation of any Intellectual Property Right of any third party.</Pt>
      </Pt>

      <Pt i="11.2.">We represent that We have all rights and authorisations to provide You with access to the MDXT Data Marketplace. In no
        event shall We be responsible for or liable in respect of the Company Information, including the accuracy or completeness
        thereof.</Pt>

      <Pt i="11.3.">The MDXT Data Marketplace may be temporarily unavailable for scheduled maintenance or for unscheduled emergency
        maintenance, either by Us or by third party providers, or because of other causes outside of Our reasonable control, but
        We shall use reasonable efforts to provide advance notice by email of any scheduled service disruption. However, use of
        the MDXT Data Marketplace and any reliance by You upon the MDXT Data Marketplace, including any action taken by You because of such use
        or reliance is at Your sole risk. We do not warrant that the MDXT Data Marketplace will be uninterrupted or error free, nor do We
        make any warranty as to the results that may be obtained from use of the MDXT Data Marketplace. The MDXT Data Marketplace is provided
        "as is" and, except as set out in this Clause 11, We disclaim all warranties express or implied including, but not limited
        to, warranties of merchantability, fitness for a particular purpose and non-infringement.</Pt>

      <Pt i="12." as="h3">LIMITATION OF LIABILITY</Pt>

      <Pt i="12.1.">In these Terms of Use We do not seek to exclude or limit in any way our liability to You where it would be
        unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our
        employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</Pt>

      <Pt i="12.2.">We exclude all implied conditions, warranties, representations and other terms that may apply to the IOWA
        Platform or any content on it.</Pt>

      <Pt i="12.3." as="div">
        <p>We will not be liable to You for any loss or damage, whether in contract, tort (including negligence), breach of statutory
          duty or otherwise arising from or in connection with:</p>
        
        <Pt i="a)">use of, or inability to use, the MDXT Data Marketplace; or</Pt>
        <Pt i="b)">use of or reliance on any content displayed on the MDXT Data Marketplace.</Pt>
      </Pt>

      <Pt i="12.4." as="div">
        <p>In particular, We will not be liable for:</p>

        <Pt i="a)">loss of profits, sales, business, or revenue;</Pt>
        <Pt i="b)">business interruption;</Pt>
        <Pt i="c)">loss of anticipated savings;</Pt>
        <Pt i="d)">loss of business opportunity, goodwill or reputation; or</Pt>
        <Pt i="e)">loss of or corruption of data; or</Pt>
        <Pt i="f)">any indirect or consequential loss or damage.</Pt>
      </Pt>

      <Pt i="12.5.">Without prejudice to the above, neither We or our officers, directors, agents and employees will have any
        liability to You for Your use of or inability to use the MDXT Data Marketplace or for errors or omissions in the output of the
        MDXT Data Marketplace, such outputs including, without limitation, the quality or accuracy of any Company Information, screen
        displays or reports, the transmission and reception of such Company Information, and in the processing of such data by
        the MDXT Data Marketplace or other software or otherwise, even if We have been advised of the possibility of such damages or
        costs. </Pt>

      <Pt i="13." as="h3">INDEMNITY</Pt>

      <Pt i="13.1.">You agree to indemnify, defend and hold Us and our officers, directors, agents, employees and affiliates harmless
        from any and all claims and damages including legal fees and costs) arising from or relating to and any violation of these
        Terms of Use or any allegation regarding Your use of the MDXT Data Marketplace or the company Information.</Pt>

      <Pt i="13.2.">You will hold Us, our officers, directors, agents and employees harmless against any claims or causes of action
        arising from or related to Your use of or inability to use the MDXT Data Marketplace.</Pt>

      <Pt i="14." as="h3">FORCE MAJEURE</Pt>

      <Pt i="14.1.">Neither Party will be liable to the other Party for any internet or telecommunications failure, computer virus,
        third party interference, or other third-party software or hardware that may interrupt or delay access to the MDXT Data Marketplace
        or cause other problems or losses, including the loss of Your Company Information. Neither Party will be liable to the other
        Party for any delay or failure to fulfil any obligations that results from an act of God, war, civil disturbance, court
        order, legislative or regulatory action or any other cause beyond that Party’s reasonable control.</Pt>

      <Pt i="15.">GENERAL</Pt>

      <Pt i="15.1."><b>Assignment and Transfer</b>: You may not assign these Terms of Use without Our prior written consent, such
        consent not to be unreasonably withheld. These Terms of Use shall be for the benefit of, and be binding upon, You and Us and
        Your and Our successors and assigns.  We may transfer our rights and obligations under these Terms of Use to another
        organisation. We will always tell You in writing if We do this and We will ensure that the transfer will not affect Your
        rights under these Terms of Use.</Pt>

      <Pt i="15.2."><b>Governing Law and Jurisdiction</b>: These Term of Use shall be governed by and construed in accordance with
        the laws of England and the Parties submit to the exclusive jurisdiction of the English courts. </Pt>

      <Pt i="15.3."><b>Severability</b>: If any provision of these Terms of Use is held invalid, illegal or unenforceable, the
        validity, legality and enforceability of any of the remaining provisions of these Terms of Use shall not in any way be
        affected or impaired. </Pt>

      <Pt i="15.4."><b>Waiver</b>: Any failure by Us to enforce or exercise any provision of these Terms of Use or related right
        shall not constitute a waiver of that right or provision. </Pt>

      <Pt i="15.5."><b>Headings</b>: The section titles used in these Terms of Use are purely for convenience and carry with them
        no legal or contractual effect.</Pt>

      <Pt i="15.6."><b>Independent Contractors</b>: The Parties are independent contractors and nothing in these Terms of Use shall be
        construed to create a partnership, joint venture, agency relationship or other joint enterprise between the Parties.</Pt>

      <Pt i="15.7."><b>Entire Agreement</b>: Save for any documents referred to herein, these Terms of Use constitute and contain the
        entire agreement between the Parties with respect to the subject matter hereof and supersedes any prior or contemporaneous
        oral or written agreements.</Pt>

      <Pt i="15.8."><b>Amendments or Modifications</b>: Except as otherwise stated herein, modifications or amendments to these Terms
        of Use shall be enforceable only if they are in writing and are signed by an authorized representative of each Party.</Pt>

      <Pt i="16." as="h3">VARIATION OF TERMS</Pt>

      <Pt i="16.1.">We reserve the right, in Our sole and absolute discretion, to modify these Terms of Use at any time and with or
        without prior notice. If these Terms of Use are modified, the modification(s) will be posted on the MDXT Data Marketplace or
        otherwise made available. You agree that by continuing to access or use the MDXT Data Marketplace after any such modification You
        are indicating that You have read, understood and agree to be bound by the modified Terms of Use. If You do not agree to
        the modified Terms of Use then You have no right to continue to access the MDXT Data Marketplace and You must cease using the IOWA
        Platform immediately. </Pt>

      <h4>Version Date 25th March 2021</h4>
    </Document>
  );
};
