///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import * as theme from 'src/shared/theme';

export default function NestedFolder({ selected, folder, openFolders, onToggle }) {
  const { id } = folder;
  const hasChildren = folder.folders.length > 0;
  const isOpen = openFolders.includes(id) || selected.includes(id);

  return (
    <Container>
      <Header>
        <Icon>{hasChildren && <i className={`fa fa-fw fa-folder${isOpen ? '-open' : ''}`} onClick={() => onToggle(folder.id)} />}</Icon>
        <Name exact activeClassName="is-active" to={`/downloads${folder.id ? `/${folder.id}` : ''}`}>{folder.name}</Name>
      </Header>
      {isOpen && hasChildren && (
        <Children>
          {folder.folders.map(child => <NestedFolder key={child.id} folder={child} {...{ selected, openFolders, onToggle }} />)}
        </Children>
      )}
    </Container>
  );
}

const Container = styled.div`
`;

const Header = styled.header`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 5px 5px;
`;

const Icon = styled.div`
  font-size: 1em;
  margin-top: 3px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  color: ${theme.brand_primary};
`;

const Name = styled(NavLink)`
  font-size: 16px;
  text-decoration: none;
  color: inherit;
  flex-grow: 1;
  opacity: 0.75;
  &:hover {
    font-weight: bold;
    opacity: 1;
  }
  &.is-active {
    font-weight: bold;
    opacity: 1;
  }
`;

const Children = styled.div`
  padding-left: 10px;
  margin-left: 15px;
  border-left: 1px dotted #aaa;
`;