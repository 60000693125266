///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';

export default function BlankSlate({ type }) {
  return (
    <Container>
      <Title>No {type} found</Title>
      <Message>We couldn't find any {type} that match your search criteria.</Message>
    </Container>
  );
}

export function CustomBlankSlate({ title, message }) {
  return (
    <Container>
      <Title>{title}</Title>
      <Message>{message}</Message>
    </Container>
  );
}

const Container = styled.div`
  padding: 10px;
  flex-grow: 1;
  ${theme.above_mobile} {
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-size: 26px;
  font-weight: bold;
  margin: 0;
`;

const Message = styled.p`
  margin-top: 10px;
`;