///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import styled from 'styled-components';

export const Input = styled.input`
  box-sizing: border-box;
  height: 36px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
  box-shadow: none;
  background: white;
`;

export const Textarea = styled.textarea`
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.5);
  background: white;
  padding: 8px 10px;
  box-shadow: none;
  margin: 0 !important;
  max-width: 100%;
`;