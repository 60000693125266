///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import useLocalStorage from 'src/shared/lib/useLocalStorage';
import apolloClient from 'src/shared/lib/apolloClient';

const isoContext = React.createContext();

export default isoContext;

export function ServerProvider({ req, token, siteUrl, appUrl, connectUrl, analyticsId, children }) {
  const mode = 'server';
  const url = `${appUrl}${req.originalUrl}`;
  const findHistory = () => {};
  const loggedIn = !!token;

  const loginUrl = `/login?redirect=${encodeURIComponent(url)}`;

  const context = { loggedIn, mode, url, siteUrl, appUrl, connectUrl, loginUrl, findHistory };

  return (
    <>
      <script dangerouslySetInnerHTML={{ __html: `
        window.ISO_CONTEXT = ${JSON.stringify({ token, siteUrl, appUrl, connectUrl, analyticsId })};`}}></script>
      <ApolloProvider client={apolloClient(token, true)}>
        <isoContext.Provider value={context}>
          {children}
        </isoContext.Provider>
      </ApolloProvider>
    </>
  );
}

export function ClientProvider({ children }) {
  const mode = 'client';

  // useLocation will refresh the context any time the URL changes,
  // but does not actually provide the full URL - so we read that from
  // window instead.
  const location = useLocation();
  const url = window.location.href;
  const { token, siteUrl, appUrl, connectUrl } = window.ISO_CONTEXT;
  const client = React.useMemo(() => apolloClient(token), [token]);
  const [savedHistory, setHistory] = useLocalStorage('history', { json: true });
  const history = savedHistory || [];
  const loginUrl = `/login?redirect=${encodeURIComponent(url)}`;
  const loggedIn = !!token;

  React.useEffect(() => {
    const lastLocation = history.length > 0 ? history[0] : null;
    if(!lastLocation || lastLocation.pathname !== location.pathname || lastLocation.search !== location.search) {
      const newHistory = [location, ...history];
      setHistory(newHistory.slice(0, 5));
    } 
  }, [url]);

  const findHistory = (paths) => {
    for(const prevLocation of history) {
      if(paths.find(path => prevLocation?.pathname.match(path))) {
        return prevLocation;
      }
    }

    return null;
  };

  const logout = () => {
    const form = document.createElement('form');
    form.action = '/logout';
    form.method = 'POST';
    document.body.appendChild(form);
    form.submit();
  }

  const context = { loggedIn, mode, url, siteUrl, appUrl, connectUrl, loginUrl, findHistory, logout };

  return (
    <>
      <script dangerouslySetInnerHTML={{ __html: `
        window.ISO_CONTEXT = ${JSON.stringify(window.ISO_CONTEXT)};`}}></script>
      <ApolloProvider client={client}>
        <isoContext.Provider value={context}>
          {children}
        </isoContext.Provider>
      </ApolloProvider>
    </>
  );
}