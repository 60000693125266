///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import IframeResizer from 'iframe-resizer-react'
import isoContext from 'src/app/contexts/iso';
import * as theme from 'src/shared/theme';

export default function ProductPage({ page }) {
  const { connectUrl } = React.useContext(isoContext);
  const [isOpen, setOpen] = React.useState(true);
  const onToggle = () => setOpen(!isOpen);

  return (
    (
      <Page key={page.id}>
        <PageHeader onClick={onToggle}>
          <PageTitle>{page.name}</PageTitle>
          <PageToggle className={`fa fa-chevron-${isOpen ? 'down' : 'left'}`} />
        </PageHeader>
        {isOpen ? (
          <PageContents>
            <PageIFrame
              sizeWidth={false}
              scrolling="omit"
              style={{ maxHeight: '70vh' }}
              src={`${connectUrl}/render/embed?ppdtag=${page.ppd_tag}`}
            />
          </PageContents>
        ) : null}
      </Page>
    )
  );
}

const Page = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35);
  background: ${theme.brand_primary};
  &:last-child {
    margin-bottom: 0;
  }
`;

const PageHeader = styled.header`
  padding: 8px;
  height: 20px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const PageTitle = styled.h2`
  font-size: 14px;
  font-weight: normal;
  flex-grow: 1;
  margin: 0;
`;

const PageToggle = styled.i`
  font-size: 12px;
  opacity: 0.25;
`;

const PageContents = styled.div`
  padding: 0 2px 2px;
`;

const PageIFrame = styled(IframeResizer)`
  width: 100%;
  height: 500px;
  border: none;
`;
