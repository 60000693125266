///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import FakeText from 'src/shared/components/FakeText';
import { Section } from 'src/app/components/Layout';
import FacetValue from './Value';
import FacetFilter from './Filter';

function getBucketId(value) {
  return value.id || 'any';
}

export default function Facet({ facet, selected, onSelect, isOpen, onToggle, onClear }) {
  const [search, setSearch] = React.useState('');
  const { values } = facet;
  
  if(values.length === 0) {
    return null;
  }

  // attach an index to access values by parent_id
  const valuesByParentId = values.reduce((idx, val) => {
    idx[val.parent_id] = idx[val.parent_id] || [];
    idx[val.parent_id].push(val);
    return idx;
  }, {});

  const provideSearch = facet.is_multi && Object.keys(valuesByParentId).length > 1;
  
  let note = '';
  if(facet.is_multi && selected.length > 0) {
    note = `${selected.length} selected`;

  } else if(!facet.is_multi && selected) {
    note = values.find(v => v.id == selected).label;
  }

  let rootValues = valuesByParentId[null];
  if(search) {
    rootValues = values.filter(val => val.label.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <Section key={facet.id} note={note} name={facet.name} isOpen={isOpen} onToggle={onToggle} onClear={onClear}>
      {provideSearch && (
        <FacetFilter value={search} onChange={setSearch} />
      )}
      {rootValues.map(function renderFacetValue(value) {
        const bucket = bucket && bucket[getBucketId(value)];
        const isSelected = facet.is_multi ? selected.includes(value.id.toString()) : selected == value.id;

        const children = valuesByParentId[value.id] || [];
        return (
          <FacetValue
            facet={facet}
            value={value}
            bucket={bucket}
            isSelected={isSelected}
            onSelect={() => onSelect(value)}
            children={children.length > 0 ? children.map(renderFacetValue) : null}
          />
        );
      })}
    </Section>
  );
}

export function FakeFacet({ items }) {
  return (
    <Section name={<FakeText width="150px" height={10} opacity={0.25} />} isOpen onToggle={() => {}}>
      <OptionGroup>
          {Array(items).fill().map((_, i) => (
            <Option key={i}>
              <input type="checkbox" onChange={() => {}} />
              <OptionLabel><FakeText opacity={0.125} /></OptionLabel>
            </Option>
          ))}
      </OptionGroup>
    </Section>
  );
}

const OptionGroup = styled.div`

`;

const Option = styled.label`
  display: flex;
  flex-direction: row;
  padding: 2px 0;
  cursor: pointer;
  align-items: center;
`;

const OptionLabel = styled.div`
  padding-left: 5px;
  user-select: none;
`;
