///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { format, parseISO, getYear, differenceInDays } from 'date-fns';
import * as theme from 'src/shared/theme';
import { Footer } from 'src/app/components/Layout';
import Subscription from './Subscription';
import Request from './Request';

function formatExpiry(sub) {
  const expiry = parseISO(sub.expires_at);

  const dateFormat = getYear(expiry) === getYear(new Date()) ? 'do MMM' : 'do MMM y';
  let style = 'neutral';
  let prefix = 'Expires';

  const remaining = differenceInDays(expiry, new Date());
  if(remaining < 0) {
    prefix = 'Expired';
    style = 'expired';

  } else if(remaining <= 30) {
    style = 'expiring';
  }
  
  return (
    <Expiry modifier={style}>
      {prefix} <ExpiryDate>{format(expiry, dateFormat)}</ExpiryDate>
    </Expiry>
  );
}

function byProductName(a, b) {
  return a.product.name.toUpperCase().localeCompare(b.product.name.toUpperCase());
}

function byProviderName(a, b) {
  return a.provider.name.toUpperCase().localeCompare(b.provider.name.toUpperCase());
}

export default function SubscriptionList({ items, recordtype }) {
  const history = useHistory();
  const { type, id: selectedId } = useParams();

  const index = items.reduce((acc, item) => {
    acc[item.product.provider.id] = acc[item.product.provider.id] || {
      provider: item.product.provider,
      items: [],
    };

    acc[item.product.provider.id].items.push(item);
    return acc;
  }, {});

  const providers = Object.values(index).sort(byProviderName);

  const firstProvider = providers[0];

  let selected = selectedId && items.find(sub => sub.id == selectedId && sub.type === recordtype);
  if(!selected) {
    selected = firstProvider.items[0];
  }

  const onSelect = (subscription) => {
    history.push(`/subscriptions/${type}/${subscription.type}/${subscription.id}`);
  }
  
  return (
    <Container>
      <Subscriptions>
        <Background>
          {providers.map(({ items, provider }) => {
            return (
              <React.Fragment key={provider.id}>
                <Section>
                  <Info>
                    <Name>{provider.name}</Name>
                  </Info>
                  {provider.logo_url && (
                    <LogoWrapper>
                      <Logo src={`/logos/${provider.id}.png`} alt={`${provider.name} logo`} />
                    </LogoWrapper>
                  )}
                </Section>
                <Options>
                  {items.sort(byProductName).map(item => (
                    <Option key={item.id} selected={item.id == selected?.id} onClick={() => onSelect(item)}>
                      <ProductInfo>
                        <ProductTitle>{(item.product.parent || item.product).name}</ProductTitle>
                        <ProductType>
                          {item.type === 'subscription' && formatExpiry(item)}
                          {item.type === 'request' && (item.product.parent ? 'Request for Sample Access' : 'Request for Quote')}
                        </ProductType>
                      </ProductInfo>
                      <HoverOverlay />
                    </Option>
                  ))}
                </Options>
              </React.Fragment>
            );
          })}
        </Background>
      </Subscriptions>
      <Contents>
        <Window>
          {(() => {
            if(!selected) return null;

            switch(selected.type) {
              case 'subscription': return <Subscription subscription={selected} />;
              case 'request': return <Request request={selected} />;
            }
          })()}
        </Window>
        <Footer />
      </Contents>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  flex-direction: column-reverse;
  ${theme.above_mobile} {
    flex-direction: row;
  }
`;

const Contents = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 3px;
  overflow-y: auto;
`;

const Window = styled.div`
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const Subscriptions = styled.div`
  flex-shrink: 0;
  background: url(/bg4.png) #f3f3f3;
  overflow-y: auto;
  ${theme.above_mobile} {
    width: 400px;
  }
`;

const Background = styled.div`
`;

const HoverOverlay = styled.div`
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
`;

const Section = styled.header`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  padding: 10px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.125);
  margin-bottom: 5px;
  background: white;
`;

const Info = styled.div`
  flex-grow: 1;
  white-space: nowrap;
`;

const LogoWrapper = styled.div`
  max-width: 25%;
  margin-left: 20px;
`;

const Logo = styled.img`
  display: block;
  max-height: 20px;
  max-width: 100%;
  margin: 0 auto;
  // image-rendering: -webkit-optimize-contrast;
`;

const Name = styled.h1`
  font-size: 16px;
  margin: 0;
`;


const Options = styled.div`
  padding: 0 5px;
`;

const Option = styled.article`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  padding: 2px 5px 5px;
  margin: 5px 0;
  & + * {
    border-top: none;
  }
  ${({ selected }) => selected ? `
    background: #fff;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.125);
` : `
  &:hover {
    background: url(/bg4.png) #f3f3f3;
  }
  `}
`;

const ProductType = styled.div`
  font-size: 14px;
`;

const ProductTitle = styled.h2`
  font-size: 16px;
  font-weight: normal;
  margin: 0;
`;

const ProductInfo = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  padding: 5px;
`;

const ExpiryDate = styled.span``;

const Expiry = styled.div`
  display: inline-block;
  align-self: flex-start;
  padding: 0 5px;
  margin: 5px 0 2px;
  ${({ modifier }) => {
    switch(modifier) {
      case 'expired': return `
        background: ${theme.danger};
        color: white;
        > ${ExpiryDate} {
          font-weight: bold;
        }
      `;
      case 'expiring': return `
        background: ${theme.warning};
        color: black;
        > ${ExpiryDate} {
          font-weight: bold;
        }
      `;
      case 'neutral': return `
        margin-top: 0;
        padding: 0;
      `;
    }
  }}
`;