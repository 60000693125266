///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import * as theme from 'src/shared/theme';
import basketContext from 'src/app/contexts/basket';
import { Textarea, Input } from 'src/app/components/Form';
import toggleInList from 'src/shared/lib/toggleInList';
import { PrimaryButton } from 'src/app/components/Button';
import BasketItem from 'src/app/modules/Layout/Header/Basket/Item';
import Checkbox from './Checkbox';

const REQUEST_QUOTE = gql`
  mutation($fields: RequestQuoteFields!) {
    requestQuote(fields: $fields) {
      success
      errors
    }
  }
`;

export default function QuoteForm({ onSuccess, user }) {
  const { products, remove } = React.useContext(basketContext);
  const [requestQuote] = useMutation(REQUEST_QUOTE);
  const [error, setError] = React.useState(null);

  const [formData, setFormData] = React.useState({
    name: user?.firstname ? [user.firstname, user.lastname].filter(Boolean).join(' ') : '',
    email: user?.username.match(/@/) ? user.username : '',
    phone: user?.phone || '',
    interfaces: [],
    questions: ''
  });
  
  const submitForm = (e) => {
    e.preventDefault();
    setError(null);
    requestQuote({ variables: {
      fields: { ...formData, productIds: products.map(p => p.id) }
    }})
      .then(({ data: { requestQuote }}) => {
        const { success, errors } = requestQuote;
        if(success) {
          onSuccess();

        } else {
          setError({ message: 'the following errors occurred:', errors });
        }
      })
      .catch(err => {
        setError(err.message);
      });
  };

  const setField = key => e => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  const toggleInterface = (iface) => {
    setFormData({ ...formData, interfaces: toggleInList(iface, formData.interfaces) });
  };

  const Interface = ({ label, value, description }) => (
    <Option>
      <Checkbox
        label={label}
        value={formData.interfaces.includes(value)}
        description={description}
        onChange={() => toggleInterface(value)}
      />
    </Option>
  );

  const interfaceOptions = [
    { label: 'Excel', value: 'excel', description: 'subscribe to dynamically updating data directly within your Microsoft Excel workbooks using our high-performance Excel Add-in.' },
    { label: 'Streaming APIs', value: 'streaming-apis', description: 'subscribe to dynamically updating data directly within your in-house applications using our C++, .NET, Python, JavaScript, R or FIX APIs.' },
    { label: 'REST API', value: 'rest-api', description: 'request snapshots of data on-demand via HTTP GET requests.' },
    { label: 'JSON Datafeed', value: 'json-datafeed', description: 'subscribe to dynamically updating data via a WebSocket connection, with all data delivered as blocks of JSON for easy integration with your own applications.' },
    { label: 'Web', value: 'web', description: 'view pages of streaming or snapshot data directly within your browser.' },
    { label: 'OpenFin', value: 'openfin', description: 'subscribe to dynamically updating data directly within your in-house OpenFin applications using our OpenFin desktop data service.' },
    { label: 'Symphony', value: 'symphony', description: 'view pages of streaming or snapshot data directly within your Symphony workspace using our Symphony App.' },
    { label: 'Platform', value: 'platform', description: 'deliver data from the MDX Marketplace onto your in-house market data platform (e.g. TREP) or middleware platform (e.g. Solace) using our platform integration components, allowing applications on these platforms to subscribe natively to the data.' },
    { label: 'Bespoke', value: 'bespoke', description: 'if you have data delivery requirements that can\'t be met with our \'off the shelf\' solutions we can provide bespoke integration services to meet your specific needs via our Professional Services operation.' },
  ];

  const halfway = Math.ceil(interfaceOptions.length / 2);
  const leftInterfaces = interfaceOptions.slice(0, halfway);
  const rightInterfaces = interfaceOptions.slice(halfway);

  return (
    <Form method="POST" onSubmit={submitForm}>
      <Container>
        <Columns>
          <FormColumn>
            <Title>Request a Quote</Title>
            <Wrapper>
              {error && (
                <Error>
                  <Message>{error.message}</Message>
                  {error.errors && (
                    <Errors>
                      {error.errors.map((err, i) => <li key={i}>{err}</li>)}
                    </Errors>
                  )}
                </Error>
              )}
              <FieldColumns>
                <FieldColumn>
                  <Field>
                    <Label>Full Name</Label>
                    <Input type="text" value={formData.name} onChange={setField('name')} />
                  </Field>
                  <Field>
                    <Label>Phone Number</Label>
                    <Input type="text" value={formData.phone} onChange={setField('phone')} />
                  </Field>
                </FieldColumn>
                <FieldColumn>
                  <Field>
                    <Label>Email Address</Label>
                    <Input type="email" value={formData.email} onChange={setField('email')} />
                  </Field>
                </FieldColumn>
              </FieldColumns>
              <Field>
                <Label>Additional Questions</Label>
                <Textarea rows={3} value={formData.questions} onChange={setField('questions')} />
              </Field>
            </Wrapper>
          </FormColumn>
          <BasketColumn>
            <Title>Your Basket</Title>
            <Products>
              {products.map(product => (
                <BasketItem key={product.id} product={product} onRemove={() => remove(product)} />
              ))}
            </Products>
          </BasketColumn>
        </Columns>
        <Field as="div">
          <Title>Interfaces</Title>
          <Note>How do you want to access this data?</Note>
          <Options>
            <FieldColumns>
              <FieldColumn>
                {leftInterfaces.map((option) => (
                  <Interface {...option} key={option.value} />
                ))}
              </FieldColumn>
              <FieldColumn>
                {rightInterfaces.map((option) => (
                  <Interface {...option} key={option.value} />
                ))}
              </FieldColumn>
            </FieldColumns>
          </Options>
        </Field>
        <Footer>
          <PrimaryButton type="submit">Request a Quote</PrimaryButton>
        </Footer>
      </Container>
    </Form>
  );
}

const Container = styled.div`
  padding: 20px 0;
  margin: 0 auto;
  ${theme.above_mobile} {
    width: 100%;
    max-width: 1080px;
  }
`;

const Title = styled.h1`
  font-size: 26px;
  margin: 0 0 20px;
  font-weight: normal;
`;

const Columns = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${theme.above_mobile} {
    flex-direction: row;
    align-items: stretch;
    padding-left: 0;
  }
`;

const Column = styled.div`
  box-sizing: border-box;
  ${theme.above_mobile} {
    padding: 0 10px;
  }
  &:first-child {
    padding-left: 0 !important;
  }
  &:last-child {
    padding-right: 0 !important;
  }
`;

const FormColumn = styled(Column)`
  width: 50%;
`;

const BasketColumn = styled(Column)`
  width: 50%;
`;

const Error = styled.div`
  margin: 0 0 20px;
  padding: 15px 20px;
  background: ${theme.danger};
  color: white;
  text-shadow: 0 1px 1px black;
`;

const Message = styled.p`
  margin: 0;
`;

const Errors = styled.ul`
  margin: 10px 0 0;
`;

const Wrapper = styled.div`
`;

const Products = styled.div``;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FieldColumns = styled.div`
  display: flex;
  flex-direction: row;
`;

const FieldColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 50%;
  padding: 0 10px;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`;

const Field = styled.label`
  display: block;
  padding-bottom: 15px;
`;

const Label = styled.span`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Note = styled.p`
  opacity: 0.5;
  margin: 0 0 5px;
`;

const Options = styled.ul`
  list-style: none;
  padding: 0;
`;

const Option = styled.li`
`;

const Footer = styled.div`
  margin-top: 10px;
`;
