///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { Document } from 'src/app/components/Document';

export default function PrivacyPolicy() {
  return (
    <Document>
      <h1>MDX Technology Limited</h1>
      <h2>Privacy Notice</h2>
      <p>We are committed to protecting and respecting your privacy.</p>
      <p>This notice sets out the basis on which any personal data we collect from you,
        or that you provide to us, will be processed by us.  Please read the following
        carefully to understand how we will use your personal data and your rights in
        relation to your personal data.</p>
      <p>This privacy notice contains the following information:  </p>
      <ol>
        <li>Important information and who we are;</li>
        <li>The data we collect about you;</li>
        <li>How is your personal data collected;</li>
        <li>How we use your personal data;</li>
        <li>Disclosures of your personal data;</li>
        <li>International transfers;</li>
        <li>Data security;</li>
        <li>Data retention; and</li>
        <li>Your legal rights.</li>
      </ol>

      <h3>1. Important information and who we are</h3>
      <p>We are MDX Technology Limited (“<b>MDXT</b>”). We are registered in England and Wales under
        company number 07305821 and have our registered office at 2nd Floor Regis House, 45 King
        William Street, London, United Kingdom, EC4R 9AN.</p>
      <p>References to “we", "us" or "our" in this privacy notice, refer to MDXT. </p>
      
      <h4>Controller</h4>
      <p>MDXT is the controller and responsible for your personal data.</p>
      <p>If you have any questions about this privacy notice, including any requests to exercise your
        legal rights, please contact us by emailing <a href="mailto:data-protection@mdxtechnology.com">data-protection@mdxtechnology.com</a>.</p>
      
      <h4>Changes to our privacy notice</h4>
      <p>Any changes we make to our privacy notice in the future will be posted on this page. Please
        check back frequently to see any updates or changes to our privacy notice.</p>
      
      <h4>Third party sites</h4>
      <p>Our website may, from time to time, contain links to and from the websites of other
        businesses.  If you follow a link to any of these websites, please note that these websites
        have their own privacy policies and that we do not accept any responsibility or liability for
        these policies.  Please check their privacy notices before you submit any personal data to
        these websites.</p>

      <h3>2. The data we collect about you</h3>
      <p>Personal data, or personal information, means any information about an individual from which
        that person can be identified. It does not include data where the identity has been removed
        (anonymous data).</p>
      <p>We may collect, use, store and transfer different kinds of personal data about you which we
        have grouped together follows:</p>
      
      <ul>
        <li><b>Identity Data</b> includes first name, last name, username or similar identifier,
          employer and position/title.</li>
        <li><b>Contact Data</b> includes address, email address and telephone numbers.</li>
        <li><b>Technical Data</b> includes internet protocol (IP) address, browser type and version, time
          zone setting and location, browser plug-in types and versions, operating system and platform
          and other technology on the devices you use to access our website.</li>
        <li><b>Transaction Data</b> includes details about payments to and from you and other details of
          products and services you have purchased from us or we have bought from you.</li>
        <li><b>Marketing and Communications Data</b> includes your preferences in receiving marketing from
          us and your communication preferences.</li>
      </ul>

      <p>We may also collect, use and share <b>Aggregated Data</b> such as statistical or demographic data
        for any purpose. Aggregated Data may be derived from your personal data but is not considered
        personal data in law as this data does <b>not</b> directly or indirectly reveal your identity. For
        example, we may aggregate statistics to understand usage of our website. However, if we combine
        or connect Aggregated Data with your personal data so that it can directly or indirectly identify
        you, we treat the combined data as personal data which will be used in accordance with this privacy
        notice.</p>

      <p>We do not collect any <b>Special Categories of Personal Data</b> about you (this includes details
        about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation,
        political opinions, trade union membership, information about your health and genetic and biometric
        data). Nor do we collect any information about criminal convictions and offences.</p>

      <h3>3. How is your personal data collected?</h3>
      <p>We use different methods to collect data from and about you including through:</p>
      <p><b>Direct interactions</b>. You may give us your Identity Data, Contact Data and Marketing and Communications
        Data by corresponding with us at industry events or other events, by phone, email or by filling in
        forms on our website. This includes personal data you provide when:</p>

      <ul>
        <li>You give us your business card so that we may contact you.</li>
        <li>You email us to find out about our products and services.</li>
        <li>You purchase or enter into negotiations to purchase products or services we offer.</li>
        <li>You contact us through our website.</li>
        <li>You communicate with us to provide your services to us or to sell us your products or services.</li>
        <li>You provide us with feedback. </li>
      </ul>

      <p><b>Automated technologies or interactions</b>. As you interact with our website, we may automatically
        collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data
        by using cookies, server logs and other similar technologies.  Please see our Cookie Policy for further
        details.</p>
      <p><b>Third parties or publicly available sources</b>. We may receive personal data about you from various
        third parties and public sources as set out below: </p>
      
      <ul>
        <li>Identity Data and Contact Data from people who introduce us to you.</li>
        <li>Technical Data from analytics providers such as Google Analytics.</li>
        <li>Identity Data and Contact Data from publicly availably sources such as Linked In, networking groups
          and other websites.</li>
      </ul>

      <h3>4. How we use your personal data</h3>
      <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal
        data in the following circumstances:</p>

      <ul>
        <li>Where you have given your consent to the processing of your personal data for a particular purpose.</li>
        <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and
          fundamental rights do not override those interests.</li>
        <li>Where it is necessary for the performance of a contract with you.</li>
        <li>Where we need to comply with a legal or regulatory obligation.</li>
      </ul>

      <h4>Purposes for which we will use your personal data</h4>
      <p>We have set out below a description of all the ways we plan to use your personal data, and which of the
        legal bases we rely on to do so. We have also identified what our legitimate interests are where
        appropriate.  Note that we may process your personal data for more than one lawful ground depending on the
        specific purpose for which we are using your data.</p>

      <table cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            <th>Purpose/Activity</th>
            <th>Lawful basis for processing including basis of legitimate interest</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>To build a database of contacts and prospects who might be interested in our products and services
              and to inform our marketing and sales activities, including market mapping, client and prospect mapping
              and analysis</td>
            <td>Necessary for our legitimate interests (so that we can market and sell our products and services)</td>
          </tr>
          <tr>
            <td>To invite you to our events and send you our marketing communications</td>
            <td>
              (a)	Necessary for our legitimate interests (so that we can market our products and services to B2B customers)<br />
              (b)	Your consent (if your consent is required)
            </td>
          </tr>
          <tr>
            <td>To provide you with products and services you have purchased from us or are in negotiations to
              purchase from us including communications, managing the provision of your services including support
              services and to manage and collect fees</td>
            <td>
              (a)	Performance of a contract with you<br />
              (b)	Necessary for our legitimate interests (to recover amounts due to us)
            </td>
          </tr>
          <tr>
            <td>To engage you as an associate or consultant to provide services to us</td>
            <td>
              (a)	Performance of a contract with you<br />
              (b)	Necessary to comply with a legal obligation
            </td>
          </tr>
          <tr>
            <td>To buy products and services from you</td>
            <td>Performance of a contract with you</td>
          </tr>
          <tr>
            <td>
              To manage our relationship with you which will include:<br />
              (a) Notifying you about changes to our terms or privacy notice<br />
              (b) Asking you to update your information
            </td>
            <td>
              (a) Necessary to comply with a legal obligation<br />
              (b) Necessary for our legitimate interests (to keep our records and database updated)
            </td>
          </tr>
          <tr>
            <td>To administer and protect our business and website (including troubleshooting, data analysis,
              testing, system maintenance, support, reporting and hosting of data)</td>
            <td>
              (a) Necessary for our legitimate interests (for running our business, provision of
              administration and IT services, network security, to prevent fraud and in the
              context of a business reorganisation or group restructuring exercise)<br />
              (b) Necessary to comply with a legal obligation
            </td>
          </tr>
        </tbody>
      </table>

      <h4>Marketing</h4>
      <p>You may receive our communications or invitations to our events if you have requested information
        from us, if you provided us with your details or if we have or if we have identified you as someone
        who may be interested in our products and services and you have not unsubscribed from our marketing
        communications.</p>

      <h4>Opting out</h4>
      <p>You can ask us to stop sending you marketing messages at any time by following the opt-out links on
        any marketing message sent to you or by emailing <a href="mailto:marketing@mdxtechnology.com">marketing@mdxtechnology.com</a> at any time. </p>

      <h4>Cookies</h4>
      <p>Our websites use cookies to distinguish you from other users of our websites. This helps us to
        provide you with a good experience when you browse our websites and also allows us to improve our
        sites. For detailed information on the cookies we use and the purposes for which we use them see
        our <a href="https://mdxtechnology.com/cookie-policy" target="_blank">Cookie Policy</a>. </p>

      <h3>5. Disclosures of your personal data</h3>
      <p>We may share your personal data with the parties set out below for the purposes set out in the table
        in paragraph 4 above.</p>
      <ul>
        <li>Service providers acting as processors who we use to help manage our business.</li>
        <li>Service providers acting as processors who we use to send our marketing communications.  We
          currently use Mailchimp which is based in the US, but complies with GDPR regulations and handles
          European data transfers as described at <a href="https://mailchimp.com/help/mailchimp-european-data-transfers/" target="_blank">https://mailchimp.com/help/mailchimp-european-data-transfers/</a>.</li>
        <li>Our associates and consultants based in the UK through whom we deliver our services.</li>
        <li>Professional advisers including lawyers, bankers, auditors and insurers based in the UK who
          provide consultancy, banking, legal, insurance and accounting services.</li>
        <li>HM Revenue &amp; Customs, regulators and other authorities based in the UK if required by such
          authorities.</li>
        <li>Third parties to whom we may choose to sell, transfer, or merge parts of our business or our
          assets. </li>
      </ul>

      <p>We require all third parties to respect the security of your personal data and to treat it in
        accordance with the law. We do not allow our third-party service providers to use your personal data
        for their own purposes and only permit them to process your personal data for specified purposes and
        in accordance with our instructions.</p>

      <h3>6. International transfers of data outside of the EEA</h3>
      <p>Your data may be transferred, stored and/or processed outside the EEA as our suppliers sometimes operate
        from outside of the EEA. We will only transfer your data outside of the EEA in compliance with data
        protection laws and provided appropriate or suitable safeguards are in place to protect your data, these
        being either Standard Contractual Clauses, Binding Corporate Rules or a Privacy Shield certification. 
        Please contact us if you would like details of the appropriate safeguards</p>

      <h3>7. Data security</h3>
      <p>We have put in place appropriate security measures to prevent your personal data from being accidentally
        lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your
        personal data to those employees, associates, consultants, contractors and other third parties who have
        business need to know. They will only process your personal data on our instructions and they are subject
        to a duty of confidentiality. </p>
      <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and
        any applicable regulator of a breach where we are legally required to do so.</p>

      <h3>8. Data retention</h3>

      <h4>How long will you use my personal data for?</h4>
      <p>We will retain your personal data for as long as necessary to provide you with our products and services.
        We will also retain your personal data as necessary to fulfil our contractual obligations and to comply
        with our legal obligations, resolve disputes, and enforce our agreements. </p>
      <p>We will retain personal data that is part of our marketing database for a period for which we think that
        information is relevant.  We actively manage our marketing database so that only relevant personal data
        is retained.</p>
      <p>Where we no longer need to process your personal data for the purposes set out in paragraph 4 of this
        Privacy Notice, we will delete your personal data from our systems unless we need to retain a limited
        amount of information to make sure that we act in accordance with your wishes.</p>
      <p>Where permissible, we will also delete your personal data on your request. Information on how to make a
        deletion request can be found in the section Your Legal Rights. </p>
      <p>If you have questions about our data retention practices, please contact us at <a href="mailto:data-protection@mdxtechnology.com">data-protection@mdxtechnology.com</a>. </p>

      <h3>9. Your legal rights</h3>
      <p>You have the right:</p>
      <ul>
        <li>To access any personal data we hold about you and we will provide a copy of your personal data together
          with details of the purposes of the processing, the types of personal data we hold and the people to whom
          your personal data has been disclosed;</li>
        <li>To have inaccurate or incomplete personal data corrected or to restrict the processing of personal data
          whilst the accuracy is checked;</li>
        <li>In certain circumstances, to have data we hold about you transferred to yourself or another data
          controller. Note, this right only applies to information that is processed by automated means which you
          initially provided consent for us to use or where we used the information to perform a contract with you;</li>
        <li>To ask to have personal data we hold about you erased. This enables you to ask us to delete or remove
          personal data where there is no good reason for us continuing to process it. You also have the right to
          ask us to delete or remove your personal data where you have successfully exercised your right to object
          to</li>
        <li>processing (see below), where we may have processed your information unlawfully or where we are required
          to erase your personal data to comply with local law;</li>
        <li>To ask us not to process your personal data for direct marketing purposes; </li>
        <li>To object to processing of your personal data where we are relying on a legitimate interest (or those
          of a third party) and there is something about your particular situation which makes you want to object to
          processing on this ground as you feel it impacts on your fundamental rights and freedoms;</li>
        <li>To withdraw consent at any time where we are relying on consent to process your personal data. However,
          this will not affect the lawfulness of any processing carried out before you withdraw your consent; and</li>
        <li>To lodge a complaint with the UK Information Commissioner’s
          Office: <a href="https://ico.org.uk/concerns/" target="_blank">https://ico.org.uk/concerns/</a> or other data protection
          supervisory authority applicable to you if you have a concern about your personal data.</li>
      </ul>
      <p>You can exercise your rights at any time by contacting us at <a href="mailto:data-protection@mdxtechnology.com">data-protection@mdxtechnology.com</a>.</p>

      <h4>What we may need from you</h4>
      <p>We may need to request specific information from you to help us confirm your identity and ensure your right to
        access your personal data (or to exercise any of your other rights). This is a security measure to ensure that
        personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you
        for further information in relation to your request to speed up our response.</p>
      
      <h4>Time limit to respond</h4>
      <p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if
        your request is particularly complex or you have made a number of requests. In this case, we will notify you and
        keep you updated. </p>

      <h4>This policy is effective as at 25th March 2021</h4>
    </Document>
  );
}
