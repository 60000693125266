///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';

export default function Header({ creator, noteBefore, noteAfter }) {
  return (
    <Container>
      <Info>
        {noteBefore && <Note>{noteBefore}</Note>}
        <Name>{creator.name}</Name>
        {noteAfter && <Note>{noteAfter}</Note>}
      </Info>
      {creator.logo_url && (
        <Logo>
          <Image src={creator.logo_url} alt={`${creator.name} logo`} />
        </Logo>
      )}
    </Container>
  );
}

const Container = styled.header`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;

const Info = styled.div``;

const Name = styled.h1`
  font-size: 26px;
  line-height: 1;
  margin: 0;
`;

const Note = styled.p`
  margin: 0;
  opacity: 0.5;
`;

const Logo = styled.div`
  max-width: 35%;
  max-height: 50px;
  margin-left: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Image = styled.img`
  display: block;
  max-height: 50px;
  max-width: 100%;
  margin: 0;
  image-rendering: -webkit-optimize-contrast;
`;