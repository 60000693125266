///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import deepmerge from 'deepmerge';
import rehype from 'rehype';
import { sanitize, defaultSchema } from 'hast-util-sanitize';
import urls from 'rehype-urls';
import rehype2react from 'rehype-react';
import styled from 'styled-components';

function transformLink(url, node) {
  if(node.tagName !== 'a') {
    return url;
  }

  // external links - rewrite to pass through forwarder
  if(url.href.match(/^(https?:)?\/\//)) {
    node.properties.target = '_blank';
    return `/forward?url=${btoa(url.href)}`;

  // internal links - not likely to occur but should be supported
  } else if(url.href.match(/^(\.|\/[^/])/)) {
    return url;

  } else {
    return null;
  }
}

const sanitizeSchema = deepmerge(defaultSchema, {
  attributes: {
    '*': ['style']
  }
});

function rehypeSanitize() {
  return (tree) => sanitize(tree, sanitizeSchema);
}

const processor = rehype()
  .use(rehypeSanitize)
  .use(urls, transformLink)
  .use(rehype2react, { createElement: React.createElement });

export default function RichText({ className, children }) {
  return (
    <Container className={className}>
      {processor.processSync(children).result}
    </Container>
  );
};

const Container = styled.div`
  :last-child {
    margin-bottom: 0 !important;
  }
  p {
    margin: 0 0 10px;
  }
  h1, h2, h3 {
    margin: 0 0 10px;
    font-size: 18px;
  }
  img {
    max-width: 100%;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }
  table {
    width: 100%;
    border-spacing: 0;

    td, th {
      border-top: 1px solid #ddd;
      border-right: 1px solid #ddd;
      padding: 8px 10px;
      box-sizing: border-box;
      height: 36px;
      background: white;
      color: rgba(0, 0, 0, 0.75);
      vertical-align: top;
      text-align: left;
    }
    th {
      border-top: 1px solid #ddd;
      border-right: 1px solid #ddd;
      font-weight: bold;
      background: #f3f3f3;
    }
    tr td:first-child, tr th:first-child {
      border-left: 1px solid #ddd;
    }
    tbody tr:last-child td {
      border-bottom: 1px solid #ddd;
    }
  }
`;