///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import FakeText from 'src/shared/components/FakeText';

export default function ProviderNotice({ provider, style }) {
  return (
    <ProductProvider style={style}>
      <ProviderBlurb>Provided by <ProviderName>{provider.name}</ProviderName></ProviderBlurb>
      {provider.logo_url && (
        <LogoWrapper>
          <ProviderImage src={provider.logo_url} alt={`${provider.name} logo`} />
        </LogoWrapper>
      )}
    </ProductProvider>
  );
};

export function FakeProviderNotice() {
  return (
    <ProductProvider>
      <ProviderBlurb>
        <div><FakeText opacity={0.25} height={6} width="20%" /></div>
        <div><FakeText opacity={0.25} height={6} width="30%" /></div>
      </ProviderBlurb>
    </ProductProvider>
  );
}

const ProductProvider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  background: #f3f3f3;
`;

const ProviderBlurb = styled.div`
  margin: 0;
  flex-grow: 1;
  padding: 5px 10px;
  font-size: 12px;
  white-space: nowrap;
`;

const ProviderName = styled.strong`
  display: block;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 128px;
  height: 26px;
  margin: 8px;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
`;

const ProviderImage = styled.img`
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  image-rendering: -webkit-optimize-contrast;
`;
