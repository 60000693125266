///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { PrimaryButton } from 'src/app/components/Button';
import Placeholder from 'src/app/components/Placeholder';
import { ProductPage } from 'src/app/modules/Subscription';

export default function ProductLiveData({ product, hasProductSubscription, quoteRequested, inBasket, requestQuote }) {
  if(hasProductSubscription) {
    if(product.pages.length > 0) {
      return (
        <>
          {product.pages.map(page => <ProductPage key={page.id} page={page} />)}
        </>
      );

    } else {
      return null;
    }
    
  } else {
    if(quoteRequested) {
      return <Placeholder message="Thank you for your interest, we will get in touch with you shortly." />;

    } else if(inBasket) {
      return <Placeholder message="This product has been added to your basket." />;

    } else {
      return (
        <Placeholder message="You do not currently have access to this product">
          <PrimaryButton onClick={requestQuote}>Request a Quote</PrimaryButton>
        </Placeholder>
      );
    }
  }
}
