///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import useCache from 'src/shared/lib/useCache';
import useQueryParams from 'src/shared/lib/useQueryParams';
const slugify = require('src/shared/lib/slugify');
import { Sidebar, Search, Section } from 'src/app/components/Layout';
import { GreyButton } from 'src/app/components/Button';
import FakeText from 'src/shared/components/FakeText';
import Facet, { FakeFacet } from './Facet';
import constructFacets from './constructFacets';

const GET_FACETS = gql`
  query {
    facets {
      id
      sort_order
      name
      is_multi
      
      enabler {
        id
        label
        facet_id
      }

      values {
        id
        facet_id
        parent_id
        label
      }
    }
  }
`;

export default function SearchSidebar({ buckets }) {
  const { data: { facets: definedFacets } = {} } = useQuery(GET_FACETS);
  const cachedFacets = useCache(definedFacets);
  const [facets, setFacets] = useQueryParams();
  const [openFilter, setOpenFilter] = React.useState('search');
  const hasFacets = Object.keys(facets).length > 0;

  const facetOptions = React.useMemo(() => {
    return cachedFacets ? constructFacets(cachedFacets, buckets) : null;
  }, [cachedFacets, buckets]);

  const facetIndex = facetOptions && facetOptions.reduce((obj, facet) => {
    obj[facet.id] = facet;
    return obj;
  }, {});
  
  const setFacet = (facet, value) => {
    setFacets({ ...facets, [slugify(facet.name)] : value });
  }

  const getValue = (facet) => {
    const value = facets[slugify(facet.name)];

    if(facet.is_multi) {
      return value && Array.isArray(value) ? value : [];

    } else {
      return value && typeof value === 'string' ? value : null;
    }
  }

  const selectValue = (facet, value) => {
    // multi-select: toggle the value
    if(facet.is_multi) {
      const selected = getValue(facet);

      if(selected.includes(value.id.toString())) {
        const removeIds = [value.id, ...getDescendantIds(value)].map(id => id.toString());
        setFacet(facet, selected.filter(id => !removeIds.includes(id)));
  
      } else {
        setFacet(facet, [...selected, value.id, ...getDescendantIds(value)]);
      }

    // not multi-select: just set the value
    } else {
      setFacet(facet, value.id);
    }
  };

  const clearFacet = (facet) => {
    if(facet.is_multi) {
      setFacet(facet, []);
    } else {
      setFacet(facet, undefined);
    }
  }

  const getDescendantIds = (value) => {
    return facetIndex[value.facet_id].values
      .filter(val => val.ancestors.find(ancestor => ancestor.id === value.id))
      .map(val => val.id);
  };

  const toggleOpen = (filter) => {
    if(openFilter === filter) {
      setOpenFilter(null);

    } else {
      setOpenFilter(filter);
    }
  }

  const isOpen = (filter) => openFilter === filter;

  const isEnabled = (facet) => {
    if(!facet.enabler) {
      return true;
    }

    const value = facets[slugify(facetIndex[facet.enabler.facet_id].name)];
    if(!value) {
      return false;
    }

    if(Array.isArray(value)) {
      return value.includes(facet.enabler.id.toString());

    } else {
      return value === facet.enabler.id.toString();
    }
  }

  const resetSearch = () => {
    setFacets({});
  }

  // remove any newly-disabled facets from the query string
  React.useEffect(() => {
    if(facetOptions) {
      const remove = facetOptions.filter(facet => facets[slugify(facet.name)] && !isEnabled(facet));

      if(remove.length > 0) {
        const cleanFacets = remove.reduce((facets, del) => {
          delete facets[slugify(del.name)];
          return facets;
        }, facets);

        setFacets(cleanFacets);
      }
    }
  }, [facets, facetOptions])

  if(!facetOptions) {
    return (
      <DisabledSidebar>
        <Section name={<FakeText width="150px" height={10} opacity={0.25} />} isOpen onToggle={() => {}}>
          <Search value="" onChange={() => {}} disabled />
        </Section>
        {Array(3).fill().map((_, i) => (
          <FakeFacet key={i} items={5} />
        ))}
      </DisabledSidebar>
    )
  }
  
  return (
    <Sidebar>
      <Header>
        <Title>Filter Results</Title>
        {hasFacets && <GreyButton onClick={resetSearch} size={12}>Reset</GreyButton>}
      </Header>
      <Section name="Search" isOpen={isOpen('search')} onToggle={() => toggleOpen('search')}>
        <Search value={facets.search || ''} onChange={e => setFacets({ ...facets, search: e.target.value })} />
      </Section>
      {facetOptions.filter(facet => isEnabled(facet)).map(facet => (
        <Facet
          key={facet.id}
          facet={facet}
          selected={getValue(facet)}
          onSelect={val => selectValue(facet, val)}
          isOpen={isOpen(facet.id)}
          onToggle={() => toggleOpen(facet.id)}
          onClear={() => clearFacet(facet)}
          />
      ))}
    </Sidebar>
  );
};

const DisabledSidebar = styled(Sidebar)`
  pointer-events: none;
  > * {
    opacity: 0.5;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  flex-grow: 1;
`;