///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export default function BackButton({ url }) {
  return (
    <Button to={url}><i className="fa fa-chevron-left" /></Button>
  );
}

const Button = styled(NavLink)`
  font-size: 20px;
  background: white;
  width: 30px;
  height: 30px;
  border: none;
  text-align: center;
  line-height: 30px;
  margin-right: 10px;
  cursor: pointer;
  color: #999;
  &:hover {
    background: #ddd;
  }
`;
