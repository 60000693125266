///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { gql, useQuery as useGraphqlQuery, useMutation } from '@apollo/client';
import * as theme from 'src/shared/theme';
import isoContext from 'src/app/contexts/iso';
import modalContext from 'src/shared/contexts/modal';
import analyticsContext from 'src/shared/contexts/analytics';
import useQueryParams from 'src/shared/lib/useQueryParams';
import userContext from 'src/app/contexts/user';
import basketContext from 'src/app/contexts/basket';
import { Footer } from 'src/app/components/Layout';
import BackButton from 'src/app/components/BackButton';
import Sidebar from './Sidebar';
import ProductInformation from './tabs/Information';
import ProductLiveData from './tabs/LiveData';
import ProductSampleData from './tabs/SampleData';
import QuoteButton from './buttons/QuoteButton';
import SampleButton from './buttons/SampleButton';

const PRODUCT_QUERY = gql`
  query($id: String!) {
    product(id: $id) {
      id
      name
      permission
      description
      data_dictionary

      accessRequest {
        id
      }
        
      subscription(expired: false) {
        id
      }

      provider {
        id
        name
        logo_url
      }
        
      pages {
        id
        name
        ppd_tag
      }

      related_products {
        id
        name
      }
      
      sample {
        id

        accessRequest {
          id
        }
        
        subscription(expired: false) {
          id
        }

        downloads {
          id
          name
          type
        }

        pages {
          id
          name
          ppd_tag
        }
      }

      facet_values {
        id
        label
        facet {
          name
          is_multi
        }
      }
    }
  }
`;

const REQUEST_SAMPLE_ACCESS = gql`
  mutation($productId: ID!) {
    requestSample(productId: $productId)
  }
`;

export default function Product() {
  let { id } = useParams();
  id = id && id.split('-')[0];
  const { loggedIn, loginUrl, findHistory } = React.useContext(isoContext);
  const { trackEvent } = React.useContext(analyticsContext);
  const { showError } = React.useContext(modalContext);
  const { data: { product: liveProduct } = {}, refetch } = useGraphqlQuery(PRODUCT_QUERY, { variables: { id } });
  const [requestSampleAccess] = useMutation(REQUEST_SAMPLE_ACCESS);
  const [product, setProduct] = React.useState();

  const [params, setParams] = useQueryParams();
  const { view: selectedTab } = params;
  const { user } = React.useContext(userContext);
  const basket = React.useContext(basketContext);
  const backRoute = findHistory([/^\/catalogue\/products$/, /^\/catalogue\/providers\/.*$/]) || '/catalogue/products';

  React.useEffect(() => {
    if(liveProduct) {
      setProduct(liveProduct);
    }
  }, [liveProduct]);

  React.useEffect(() => {
    if(product) {
      const event = { event_category: 'engagement', id: product.id, name: product.name, provider: product.provider.name, provider_id: product.provider.id };
      trackEvent('view_product', event);
    }
  }, [product]);
  
  if(!product) {
    return null;
  }

  const { sample } = product;

  const hasProductSubscription = Boolean(product.subscription);
  const quoteRequested = Boolean(product.accessRequest);
  const inBasket = Boolean(basket.products && basket.products.find(p => p.id == product.id));

  const hasSampleSubscription = Boolean(sample && sample.subscription);
  const sampleRequested = sample && Boolean(sample.accessRequest);
  const canRequestSample = sample && user && user.status === 'active' && !hasSampleSubscription;

  const selectTab = (id) => {
    setParams({ ...params, view: id });
  }

  const requestQuote = () => {
    basket.add(product);
    if(selectedTab !== 'data') {
      selectTab('data');
    }
  }

  const requestSample = async () => {
    try {
      await requestSampleAccess({ variables: { productId: sample.id } });
      await refetch();
      selectTab('samples');

    } catch(err) {
      console.error(err);
      showError('An error occurred and we did not receive your request. Please email support@mdxtechnology.com to get access.');
    }
  };

  const tabs = [
    { id: 'info', label: 'Product Information', render: () => ProductInformation({ product }) },
    { id: 'data', label: 'Live Data', render: () => ProductLiveData({ product, hasProductSubscription, quoteRequested, inBasket, requestQuote }) },
    { id: 'samples', label: 'Sample Data', render: () => ProductSampleData({ sample, hasSampleSubscription, sampleRequested, loggedIn, loginUrl, requestSample }) },
  ].filter(tab => tab.render());

  const page = tabs.find(option => option.id === selectedTab) || tabs[0] || null;

  return (
    <Layout>
      <Content>
        <Header>
          <Split>
            <Title>
              <BackButton url={backRoute} />
              <ProductTitle>{product.name}</ProductTitle>
            </Title>
            <Buttons>
              <QuoteButton {...{ disabled: hasProductSubscription, quoteRequested, inBasket, requestQuote }} />
              <SampleButton {...{ disabled: !canRequestSample, sampleRequested, requestSample }} />
            </Buttons>
          </Split>
          {tabs.length > 0 && (
            <Tabs>
              {tabs.map((option) => (
                <Tab key={option.id} onClick={() => selectTab(option.id)} selected={option === page}>{option.label}</Tab>
              ))}
            </Tabs>
          )}
        </Header>
        {page && (
          <PaneShadow>
            <Pane>
              <PaneContents>
                {page.render()}
              </PaneContents>
              <Footer />
            </Pane>
          </PaneShadow>
        )}
      </Content>
      <Sidebar product={product} />
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  ${theme.above_mobile} {
    flex-direction: row;
    overflow: hidden;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  background: url(/bg4.png) #f3f3f3;
`;

const Split = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 10px;
`;

const Title = styled.div`
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProductTitle = styled.h1`
  font-size: 24px;
  margin: 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-left: 10px;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
`;

const Tab = styled.div`
  display: inline-flex;
  padding: 8px 12px;
  ${({ selected }) => selected && `
    background-color: #fff !important;
    cursor: default !important;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.125);
  `}
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Pane = styled.div`
  position: relative;
  z-index: 2;
  background: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const PaneContents = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex-shrink: 0;
`;

const PaneShadow = styled.div`
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.125);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
