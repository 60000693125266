///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import modalContext from 'src/shared/contexts/modal';
import { FakeRows } from 'src/admin/components/Grid';
import * as theme from 'src/shared/theme';
import FakeText from 'src/shared/components/FakeText';
import isoContext from 'src/app/contexts/iso';
import Tree from './Tree';

const GET_DOWNLOAD = gql`
  query($id: ID) {
    downloadFolder(id: $id) {
      id
      name

      parent {
        id
        parent {
          id
          parent {
            id
            parent {
              id
              parent {
                id
                parent {
                  id
                }
              }
            }
          }
        }
      }
      
      files {
        id
        name
        type
        sizeReadable
      }

      folders {
        id
        name
      }
    }
  }
`;

export default function Downloads() {
  const history = useHistory();
  const { id } = useParams();
  const { showQuestion, showError } = React.useContext(modalContext);
  const { data: { downloadFolder: folder } = {}, error } = useQuery(GET_DOWNLOAD, { variables: { id }, errorPolicy: 'all' });
  const { mode, loginUrl, loggedIn } = React.useContext(isoContext);

  React.useEffect(() => {
    if(error) {
      showError(error.message);
      history.push('/catalogue');
    }
  }, [error]);

  const downloadFile = async (file) => {
    if(await showQuestion(`Download "${file.name}"?`)) {
      window.location.href = `/download-file/${file.id}`;
    }
  };

  if(!loggedIn) {
    if(mode === 'client') {
      window.location.replace(loginUrl);
    }
    return null;
  }

  return (
    <Container>
      <Layout>
        <Tree selected={folder} />
        <Folder>
          <Header>
            <Title>Downloads</Title>
          </Header>
          <FolderTitle>{folder ? folder.name : <FakeText opacity={0.125} />}</FolderTitle>
            <Grid cellSpacing={0} cellPadding={0}>
              <thead>
                <tr>
                  <ColumnHeader width="1%"></ColumnHeader>
                  <ColumnHeader>Name</ColumnHeader>
                  <ColumnHeader>Type</ColumnHeader>
                  <ColumnHeader>Size</ColumnHeader>
                </tr>
              </thead>
              <tbody>
                {folder ? (
                  <>
                    {folder.parent && (
                      <Row onClick={() => history.push(folder.parent.id ? `/downloads/${folder.parent.id}` : '/downloads')}>
                        <Cell><i className="fa fa-arrow-left" /></Cell>
                        <Cell>Back</Cell>
                        <Cell></Cell>
                        <Cell></Cell>
                      </Row>
                    )}
                    {folder.folders.map(item => (
                      <Row key={item.id} onClick={() => history.push(`/downloads/${item.id}`)}>
                        <Cell style={{ color: theme.brand_primary }}><i className="fa fa-folder" /></Cell>
                        <Cell>{item.name}</Cell>
                        <Cell></Cell>
                        <Cell></Cell>
                      </Row>
                    ))}
                    {folder.files.map(item => (
                      <Row key={item.id} onClick={() => downloadFile(item)}>
                        <Cell></Cell>
                        <Cell>{item.name}</Cell>
                        <Cell>{item.type}</Cell>
                        <Cell>{item.sizeReadable}</Cell>
                      </Row>
                    ))}
                  </>
                ) : (
                  <FakeRows rows={3} columns={4} />
                )}
              </tbody>
            </Grid>
        </Folder>
      </Layout>
    </Container>
  );
}

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.header`
`;

const Title = styled.h1`
  font-size: 24px;
  margin: 0 0 20px;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  ${theme.above_mobile} {
    flex-direction: row;
    overflow: hidden;
  }
`;

const Folder = styled.div`
  background: white;
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
`;

const FolderTitle = styled.h2`
  font-size: 20px;
  margin: 0 0 20px;
`;

const Grid = styled.table`
  width: 100%;
  margin: 0;
  ${({ size }) => {
    if(size === 'small') {
      return `
        ${ColumnHeader} {
          padding: 4px 6px;
        }
        ${Cell} {
          padding: 4px 6px;
        }
      `;
    }
  }}
`;

const ColumnHeader = styled.th`
  text-align: left;
  padding: 8px 10px;
  background: ${theme.brand_primary};
  border: 1px solid ${theme.brand_primary_border};
  border-left: none;
  &:first-child {
    border-left: 1px solid ${theme.brand_primary_border};
  }
  ${({ align }) => align && `
    text-align: ${align};
  `}
`;

const Row = styled.tr`
  ${({ onClick }) => onClick && `
    cursor: pointer;
    transform: scale(1);
    &:hover:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.15);
      pointer-events: none;
    }
  `}
  > td {
    background: #fff;
  }

  &:nth-child(2n) > td {
    background: ${theme.zebra_stripe};
  }
`;

const Cell = styled.td`
  padding: 8px 10px;
  background: white;
  color: rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  &:first-child {
    border-left: 1px solid #ddd;
  }
  ${({ align }) => align && `
    text-align: ${align};
  `}
`;
