///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2021 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import modalContext from 'src/shared/contexts/modal';

const GET_ME = gql`
  query {
    me {
      connect_passkey
    }
  }
`;

export default function PasskeyModal() {
  const [copied, setCopied] = React.useState(false);
  const { closeModal } = React.useContext(modalContext);
  const { data: { me: user } = {} } = useQuery(GET_ME);

  const copyPasskey = () => {
    var el = document.createElement('textarea');
    el.value = user.connect_passkey;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  }

  if(!user) {
    return null;
  }

  return (
    <>
      <CloseButton onClick={closeModal}><i className="fa fa-times" /></CloseButton>
      <Title>Your Passkey</Title>
      <Para>Your passkey is used to access <b>ConnectExcel</b> and the <b>Connect API</b>.</Para>
      <Para>Your passkey is:</Para>
      <Wrapper>
        <Passkey>{user.connect_passkey}</Passkey>
        {copied && <Note>Copied to clipboard</Note>}
        <Copy onClick={copyPasskey}>Copy</Copy>
      </Wrapper>
    </>
  )
}

const CloseButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 20px;
  padding: 0;
  cursor: pointer;
`;

const Title = styled.h2`
  margin: 0 0 15px;
  font-size: 24px;
  line-height: 35px;
`;

const Para = styled.p`
  margin: 10px 0 0;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: row;
  background: #ddd;
  margin: 10px 0 0;
`;

const Passkey = styled.div`
  padding: 5px;
  flex-grow: 1;
`;

const Copy = styled.div`
  padding: 5px;
  background: white;
  cursor: pointer;

  &:active {
    background: #eee;
  }
`;

const Note = styled.div`
  padding: 5px 10px;
  opacity: 0.5;
`;