///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import isoContext from 'src/app/contexts/iso';
import modalContext from 'src/shared/contexts/modal';
import { PrimaryButton } from 'src/app/components/Button';
import { Table } from 'src/shared/components/Table';

const GET_CONNECTION_PARAMETERS = gql`
  query($subscriptionId: ID!, $generate: Boolean!) {
    getConnectParameters(subscriptionId: $subscriptionId, generate: $generate) {
      available
      params {
        provider
        group
        list {
          fields
          records
        }
      }
    }
  }
`;

export default function ConnectionParameters({ subscription }) {
  const subscriptionId = subscription.id;
  const [generate, setGenerate] = React.useState(false);
  const { data: { getConnectParameters } = {} } = useQuery(GET_CONNECTION_PARAMETERS, { variables: { generate, subscriptionId }});
  const { showError } = React.useContext(modalContext);

  const generateParams = () => {
    setGenerate(true);
  };

  if(!getConnectParameters) {
    return null;
  }

  const { available, params } = getConnectParameters || {};

  if(params) {
    return (
      <Table cellSpacing={0} cellPadding={0}>
        <tbody>
          <tr>
            <th>Provider</th>
            <td>{params.provider}</td>
          </tr>
          <tr>
            <th>Group</th>
            <td>{params.group}</td>
          </tr>
          {params.list && (
            <>
              <tr>
                <th>Fields</th>
                <td>{params.list.fields.join(', ')}</td>
              </tr>
              <tr>
                <th>Records</th>
                <td>{params.list.records.join(', ')}</td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    );
  }

  // available but not loaded
  if(available) {
    return (
      <PrimaryButton onClick={generateParams}>Retrieve</PrimaryButton>
    );

  // not available
  } else if(available === false) {
    return <Unavailable>Not Available For This Product</Unavailable>;
  }
}

const Unavailable = styled.div`
  padding: 30px 20px;
  background: #f3f3f3;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
`;