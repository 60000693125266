///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { createGlobalStyle } from 'styled-components';
import GlobalProvider from 'src/shared/contexts/global';
import Catalogue from './modules';

export default function App() {
  return (
    <>
      <GlobalStyle />
      <GlobalProvider>
        <Catalogue />
      </GlobalProvider>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  html {
    font-family: sofia-pro, sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 14px;
    line-height: 1.4;
  }
  body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }
`;
