///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import styled from 'styled-components';

const FakeText = styled.div`
  display: inline-block;
  vertical-align: middle;
  align-self: center;
  background: #000;
  ${({ width = '80%', height = 8, opacity = 0.5 }) => `
    width: ${width};
    height: ${height}px;
    border-radius: ${Math.floor(height / 2)}px;
    opacity: ${opacity};
  `};
`;

export default FakeText;
